<template>
  <div class="modal fade" id="addNewItem">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Add:
            <span class="text-dark text-truncate">
              {{ selectedTypeName }}
            </span>
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            v-on:click="setResetItem()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- .modal-header -->
        <div class="modal-body">
          <BioreactorAdd
            v-if="selectedType === 'bioreactor'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
            :resetItem="resetItem"
            @clearResetItem="clearResetItem"
          />
          <BranchAdd
            v-if="selectedType === 'branch'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
            :resetItem="resetItem"
            @clearResetItem="clearResetItem"
          />
          <FlaskAdd
            v-if="selectedType === 'flask'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
            :resetItem="resetItem"
            @clearResetItem="clearResetItem"
          />
          <IncubatorAdd
            v-if="selectedType === 'incubator'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
            :resetItem="resetItem"
            @clearResetItem="clearResetItem"
          />
          <MediaAdd
            v-if="selectedType === 'media'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
            :resetItem="resetItem"
            :statusObj="statusObj"
            @clearResetItem="clearResetItem"
          />
          <ManufacturerAdd
            v-if="selectedType === 'manufacturer'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <ChemicalAdd
            v-if="selectedType === 'chemical'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
            :resetItem="resetItem"
            @clearResetItem="clearResetItem"
          />
          <RecipeAdd
            v-if="selectedType === 'recipe'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
            :statusObj="statusObj"
          />
          <UserAdd
            v-if="selectedType === 'user'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <PlateAdd
            v-if="selectedType === 'plate'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
            :resetItem="resetItem"
            @clearResetItem="clearResetItem"
          />
          <ProjectAdd
            v-if="selectedType === 'project'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <StrainAdd
            v-if="selectedType === 'strain'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
            :resetItem="resetItem"
            @clearResetItem="clearResetItem"
          />
          <ExperimentAdd
            v-if="selectedType === 'experiment'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
            :resetItem="resetItem"
            :statusObj="statusObj"
            @clearResetItem="clearResetItem"
          />
          <TubeAdd
            v-if="selectedType === 'tube'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
            :resetItem="resetItem"
            @clearResetItem="clearResetItem"
          />
        </div>
        <!-- .modal-body -->

        <div class="modal-footer">
          <button
            type="button"
            v-bind:disabled="itemAddIsDisabled == true"
            class="btn btn-primary"
            v-on:click="handleAddItem(item)"
          >
            <span
              v-if="statusObj.progress"
              class="spinner-border spinner-border-sm text-light"
            ></span>
            Add
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            v-on:click="setResetItem()"
          >
            Cancel
          </button>
        </div>
        <!-- .modal-footer -->
      </div>
      <!-- .modal-content -->
    </div>
    <!-- .modal-dialog .modal-lg-->
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios.js";
import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";
import BioreactorAdd from "@/components/forms/BioreactorAdd.vue";
import BranchAdd from "@/components/forms/BranchAdd.vue";
import FlaskAdd from "@/components/forms/FlaskAdd.vue";
import IncubatorAdd from "@/components/forms/IncubatorAdd.vue";
import ManufacturerAdd from "@/components/forms/ManufacturerAdd.vue";
import MediaAdd from "@/components/forms/MediaAdd.vue";
import ChemicalAdd from "@/components/forms/ChemicalAdd.vue";
import RecipeAdd from "@/components/forms/RecipeAdd.vue";
import PlateAdd from "@/components/forms/PlateAdd.vue";
import ProjectAdd from "@/components/forms/ProjectAdd.vue";
import UserAdd from "@/components/forms/UserAdd.vue";
import StrainAdd from "@/components/forms/StrainAdd.vue";
import ExperimentAdd from "@/components/forms/ExperimentAdd.vue";
import TubeAdd from "@/components/forms/TubeAdd.vue";
import $ from "jquery";

export default {
  name: "AddForm",
  data() {
    return {
      invalidFB: OlabItem.getInvalidFeedbacks(),
      resetItem: false
    };
  },
  props: [
    "user",
    "dbInfo",
    "selectedType",
    "selectedTypeName",
    "item",
    "selectedItems",
    "statusObj"
  ],
  components: {
    BioreactorAdd,
    BranchAdd,
    FlaskAdd,
    IncubatorAdd,
    ManufacturerAdd,
    MediaAdd,
    ChemicalAdd,
    RecipeAdd,
    PlateAdd,
    ProjectAdd,
    UserAdd,
    StrainAdd,
    ExperimentAdd,
    TubeAdd
  },
  computed: {
    itemAddIsDisabled() {
      return !OlabSanity.isItemValid(this.selectedType, this.item);
    }
  },
  methods: {
    async handleAddItem(item) {
      // console.log("Add item = ", item, ", selectedType = ", this.selectedType);
      await OlabAxios.addItem(
        item,
        this.selectedType,
        this.selectedItems,
        this.statusObj
      );
      $("#addNewItem").modal("hide");
      // Done so clear entry form for next use
      this.setResetItem();
    },
    setResetItem() {
      this.resetItem = true;
    },
    clearResetItem() {
      this.resetItem = false;
    }
  }
};
</script>
