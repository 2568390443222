<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="selectedType === 'experiment' && item">
    <form>
      <div
        v-if="localStatusObj.progress"
        class="spinner-border spinner-border-sm text-primary"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <span v-if="localStatusObj.errorMessage" class="mx-1 text-danger">
        {{ localStatusObj.errorMessage }}
      </span>
      <span v-if="localStatusObj.message" class="mx-1 text-info">
        {{ localStatusObj.message }}
      </span>
      <div class="form-group">
        <label for="inp-edit-name">Name</label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-bind:class="validState(item.name)"
            v-model="item.name"
            id="inp-edit-name"
          />
        </div>
      </div>
      <div v-if="branch" class="form-group">
        <span class="mr-1">Branch:</span>
        <span class="ml-1">{{ branch.olab_id }} - {{ branch.name }}</span>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label>Category:</label>
          <div class="ml-1">{{ item.prod_data_category }}</div>
        </div>
        <div class="form-group col-md-4">
          <label for="sel-num-culture-stages">Number Of Stages:</label>
          <span
            v-if="numCultureStages == null"
            class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div v-if="numCultureStagesSettingModel2" class="mb-2">
            <VueMultiselect
              id="sel-num-culture-stages"
              v-model="numCultureStages"
              :options="numCultureStagesSettingModel2"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              :allow-empty="true"
              deselectLabel=""
              :showLabels="false"
              label="display_name"
              track-by="key_name"
            />
          </div>
          <!-- <p v-if="numCultureStages">{{ numCultureStages }}</p> -->
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inp-edit-start-date">Start Date</label>
          <div class="input-group">
            <input
              type="date"
              class="form-control"
              v-bind:class="validState(item.start_date)"
              v-model="item.start_date"
              id="inp-edit-start-date"
            />
          </div>
        </div>
        <div class="form-group col-md-4">
          <label for="inp-edit-end-date">End Date</label>
          <div class="input-group">
            <input
              type="date"
              class="form-control"
              v-model="item.end_date"
              id="inp-edit-end-date"
            />
          </div>
        </div>
      </div>
      <!-- TODO: Hypothesis component -->
      <HypothesisEdit
        :user="user"
        :item="item"
        preLoad="true"
        :resetItem="resetItem"
        @copyHypothesis="copyHypothesis"
      />
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="sel-control-strain">Control Strain:</label>
          <span
            v-if="controlStrain == null"
            class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div>
            <VueMultiselect
              id="sel-control-strain"
              v-model="controlStrain"
              :options="strains"
              :loading="isLoadingCStrn"
              :internal-search="false"
              :multiple="false"
              :close-on-select="true"
              :options-limit="10"
              :custom-label="nameInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
              @search-change="getCStrains"
            />
          </div>
          <!-- <p v-if="controlStrain">
            {{ controlStrain.olab_id }}
          </p> -->
        </div>
        <div class="form-group col-md-6">
          <label for="sel-test-strains">Test Strain(s):</label>
          <span
            v-if="testStrains == null"
            class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div>
            <VueMultiselect
              id="sel-test-strains"
              v-model="testStrains"
              :options="strains"
              :loading="isLoadingTStrn"
              :internal-search="false"
              :multiple="true"
              :close-on-select="true"
              :options-limit="10"
              :custom-label="nameInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
              @search-change="getTStrains"
            />
          </div>
          <!-- <p v-if="testStrains">
            {{ testStrains }}
          </p> -->
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="sel-related-expts">Related Experiment(s)</label>
          <div>
            <VueMultiselect
              id="sel-related-expts"
              v-model="relatedExprts"
              :options="experiments"
              :loading="isLoadingExprt"
              :internal-search="false"
              :multiple="true"
              :close-on-select="true"
              :options-limit="10"
              :custom-label="nameInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
              @search-change="getExperiments"
            />
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="sel-verifier">Verifier:</label>
          <div>
            <VueMultiselect
              id="sel-verifier"
              v-model="verifier"
              :options="users"
              :loading="isLoadingVerfr"
              :internal-search="false"
              :multiple="false"
              :close-on-select="true"
              placeholder="Select a Verifier ..."
              :options-limit="10"
              :custom-label="verifierInfo"
              label="olab_id"
              track-by="olab_id"
              @search-change="getVerifiers"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inp-verifier-sign" class="form-control-label">
            <span>Verifier Signature:</span>
          </label>
          <input
            id="inp-verifier-sign"
            class="form-control"
            type="text"
            placeholder="Verifier Signature"
            :disabled="!verifier || verifier.email !== user.email"
          />
        </div>
        <div class="form-group col-md-4">
          <label for="inp-date-verified" class="form-control-label">
            <span>Date:</span>
          </label>
          <input
            id="inp-date-verified"
            class="form-control"
            type="date"
            placeholder="Verified Date"
            :disabled="!verifier || verifier.email !== user.email"
          />
        </div>
      </div>
      <div class="form-group">
        <label for="inp-edit-desc">Description</label>
        <textarea
          type="textarea"
          class="form-control"
          v-model="item.desc"
          id="inp-edit-desc"
        ></textarea>
      </div>
      <div class="form-group">
        <label for="inp-edit-note">Note</label>
        <textarea
          type="textarea"
          class="form-control"
          v-model="item.note"
          id="inp-edit-note"
        ></textarea>
      </div>
    </form>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";
import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";
import { OlabSearch } from "@/olab/olabsearch";
import VueMultiselect from "vue-multiselect";
import HypothesisEdit from "@/components/forms/HypothesisEdit";
import { numCultureStagesSettingModel } from "@/assets/data/culture_stage_setting";

export default {
  name: "ExperimentEdit",
  data() {
    return {
      numCultureStagesSettingModel,
      numCultureStagesSettingModel2: null,
      invalidFB: OlabItem.getInvalidFeedbacks(),
      branch: null,
      numCultureStages: null,
      controlStrain: null,
      testStrains: null,
      relatedExprts: null,
      verifier: null,
      experiments: [],
      branchs: [],
      strains: [],
      users: [],
      maxCultureStageCreated: 0,
      cultures: null,
      isLoadingProj: false,
      isLoadingCStrn: false,
      isLoadingTStrn: false,
      isLoadingExprt: false,
      isLoadingVerfr: false,
      hypotheses: [
        {
          statement: "",
          cause: "",
          effect: "",
          answered: "N/A"
        },
        {
          statement: "",
          cause: "",
          effect: "",
          answered: "N/A"
        },
        {
          statement: "",
          cause: "",
          effect: "",
          answered: "N/A"
        }
      ],
      // TODO: May add status alert later
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      }
    };
  },
  components: {
    VueMultiselect,
    HypothesisEdit
  },
  props: ["user", "dbInfo", "selectedType", "item", "resetItem"],
  emits: ["clearResetItem"],
  async mounted() {
    // console.log("** dbInfo =", this.dbInfo);
    // console.log("** selectedType =", this.selectedType);
    await this.getExperiments("");
    await this.getCStrains("");
    // await this.getTStrains("");
    await this.getVerifiers("");
    // Loading is needed for the first item
    await this.loadExperimentData();
  },
  methods: {
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    nameInfo({ olab_id, name }) {
      return `${name}: [${olab_id}]`;
    },
    verifierInfo({ name, email }) {
      return `${name} - ${email}`;
    },
    async loadExperimentData() {
      if (this.item && this.item.olab_type === "experiment") {
        const itemData = await OlabAxios.getExperimentData(
          this.item,
          this.localStatusObj
        );
        if (this.numCultureStagesSettingModel && this.item) {
          this.numCultureStages = this.numCultureStagesSettingModel.find(
            (tt) => tt.key_name === this.item.num_culture_stages
          );
        }
        // console.log("itemData =", itemData);
        this.branch = itemData.branch;
        this.controlStrain = itemData.control_strain;
        this.testStrains = itemData.test_strains;
        this.relatedExprts = itemData.related_exprts;
        this.maxCultureStageCreated = itemData.maxCultureStageCreated;
        this.cultures = itemData.cultures;
        this.verifier = itemData.verifier;
        // this.hypotheses = itemData.experiment.hypotheses;

        // Setup filtered numCultureStagesSettingModel2
        if (this.numCultureStagesSettingModel) {
          this.numCultureStagesSettingModel2 = [];
          this.numCultureStagesSettingModel.forEach((ncs) => {
            const newNcs = {
              key_name: ncs.key_name,
              display_name: ncs.display_name,
              $isDisabled:
                ncs.key_name <= this.maxCultureStageCreated ? true : false
            };
            this.numCultureStagesSettingModel2.push(newNcs);
          });
        }
      }
    },
    async getExperiments(query) {
      // console.log("getExperiments: query = ", query);
      this.isLoadingExprt = true;
      const searchBody = OlabSearch.createSearchBody(
        "experiment",
        query,
        "merge"
      );
      this.experiments = await OlabAxios.getExperiments(
        searchBody,
        this.localStatusObj
      );
      this.isLoadingExprt = false;
    },
    async getCStrains(query) {
      // console.log("getCStrains: query = ", query);
      this.isLoadingCStrn = true;
      const searchBody = OlabSearch.createSearchBody("strain", query, "merge");
      this.strains = await OlabAxios.getStrains(
        searchBody,
        this.localStatusObj
      );
      this.isLoadingCStrn = false;
    },
    async getTStrains(query) {
      // console.log("getTStrains: query = ", query);
      this.isLoadingTStrn = true;
      const searchBody = OlabSearch.createSearchBody("strain", query, "merge");
      this.strains = await OlabAxios.getStrains(
        searchBody,
        this.localStatusObj
      );
      this.isLoadingTStrn = false;
    },
    async getVerifiers(query) {
      // console.log("getVerifiers: query = ", query);
      this.isLoadingVerfr = true;
      const searchBody = OlabSearch.createSearchBody("user", query, "merge");
      this.users = await OlabAxios.getUsers(searchBody, this.localStatusObj);
      this.isLoadingVerfr = false;
    },
    copyHypothesis(idx, hypo) {
      // console.log("** copyHypothesis: idx =", idx, ", ", hypo);
      if (idx >= 0 && idx < 3 && hypo) {
        this.hypotheses[idx].statement = hypo.statement;
        this.hypotheses[idx].cause = hypo.cause;
        this.hypotheses[idx].effect = hypo.effect;
        this.hypotheses[idx].answered = hypo.answered;
      }
      // console.log(this.hypotheses[idx]);
      this.item.setHypotheses(this.hypotheses);
    }
  },
  watch: {
    relatedExprts() {
      // console.log("relatedExprts =", this.relatedExprts);
      const tmpReExprtIDs = [];
      if (this.relatedExprts && this.relatedExprts.length > 0) {
        this.relatedExprts.forEach((re) => {
          tmpReExprtIDs.push(re.olab_id);
        });
      }
      // console.log("tmpStrnIDs =", tmpStrnIDs);
      this.item.setRelatedExprts(tmpReExprtIDs);
    },
    branch() {
      this.item.setBranch(this.branch);
    },
    numCultureStages() {
      const ncs =
        this.numCultureStages && this.numCultureStages.key_name
          ? this.numCultureStages.key_name
          : 0;
      this.item.setNumCultureStages(ncs);
    },
    controlStrain() {
      this.item.setControlStrain(this.controlStrain);
    },
    testStrains() {
      // console.log("testStrains =", this.testStrains);
      const tmpStrnIDs = [];
      if (this.testStrains && this.testStrains.length > 0) {
        this.testStrains.forEach((ts) => {
          tmpStrnIDs.push(ts.olab_id);
        });
      }
      // console.log("tmpStrnIDs =", tmpStrnIDs);
      this.item.setTestStrains(tmpStrnIDs);
    },
    verifier() {
      this.item.setVerifier(this.verifier);
    },
    resetItem() {
      // Clean previously set fields once add is excuted
      // console.log("ExperimentEdit:resetItem() - olab_id =", this.item.olab_id);
      this.branch = null;
      this.controlStrain = null;
      this.testStrains = null;
      this.relatedExprts = null;
      this.verifier = null;
      // this.experiments = [];
      // this.branchs = [];
      // this.strains = [];
      // this.users = [];
      this.$emit("clearResetItem");
    },
    "item.olab_id"() {
      // console.log("watch: item.olab_id: ", this.item.olab_id);
      this.loadExperimentData();
    }
  }
};
</script>
