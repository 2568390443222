/*
 * Here’s a set of 20 highly distinguishable colors in hex format, spaced across the color spectrum to maximize contrast in hue, brightness, and saturation:
 *
 * #FF0000FF – Red
 * #00FF00FF – Green
 * #0000FFFF – Blue
 * #FFFF00FF – Yellow
 * #FF00FFFF – Magenta
 * #00FFFFFF – Cyan
 * #FF8000FF – Orange
 * #8000FFFF – Purple
 * #00FF80FF – Spring Green
 * #FF0080FF – Pink
 * #80FF00FF – Lime
 * #0080FFFF – Sky Blue
 * #FF8080FF – Light Red
 * #80FF80FF – Light Green
 * #8080FFFF – Light Blue
 * #FFFF80FF – Light Yellow
 * #FF80FFFF – Light Magenta
 * #80FFFFFF – Light Cyan
 * #FF8040FF – Coral
 * #8040FFFF – Violet
 *
 * Characteristics:
 * Wide hue range: Colors span the full spectrum, including red, green, blue, yellow, purple, and more.
 * Sufficient brightness and saturation: Ensures colors are vibrant and easy to differentiate.
 * No dark shades: Avoids overly dark colors that can be hard to distinguish, especially on a dark background.
 * This palette should work well for visualizations, charts, or UI elements where distinguishable colors are important.
 */
class OlabColorPool {
  static getColorByNum(num) {
    num = num % 20;
    let clr = "#000000FF";
    switch (num) {
      case 0:
        return "#FF0000FF";
      case 1:
        return "#00FF00FF";
      case 2:
        return "#0000FFFF";
      case 3:
        return "#FFFF00FF";
      case 4:
        return "#FF00FFFF";
      case 5:
        return "#00FFFFFF";
      case 6:
        return "#FF8000FF";
      case 7:
        return "#8000FFFF";
      case 8:
        return "#00FF80FF";
      case 9:
        return "#FF0080FF";
      case 10:
        return "#80FF00FF";
      case 11:
        return "#0080FFFF";
      case 12:
        return "#FF8080FF";
      case 13:
        return "#80FF80FF";
      case 14:
        return "#8080FFFF";
      case 15:
        return "#FFFF80FF";
      case 16:
        return "#FF80FFFF";
      case 17:
        return "#80FFFFFF";
      case 18:
        return "#FF8040FF";
      case 19:
        return "#8040FFFF";
    }
    return clr;
  }
}

export { OlabColorPool };
