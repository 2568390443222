<template>
  <div class="row">
    <label>{{ name }}</label>
    <div class="container">
      <Chrome v-model="localColor" />
    </div>
    <!-- <p class="mt-3">Selected color: {{ localColor }}</p> -->
  </div>
</template>

<script>
import { Chrome } from "@ckpack/vue-color";

export default {
  components: {
    Chrome
  },
  data() {
    return {
      localColor: { hex: "#000000" }
    };
  },
  props: ["name", "color"],
  mounted() {
    this.localColor = this.color;
    // console.log("color =", this.color);
    // console.log("localColor =", this.localColor);
  },
  watch: {
    color(newValue) {
      // console.log("Watch - ColorPickerUI: color =", newValue);
      this.localColor = newValue;
    },
    localColor(newValue) {
      // console.log("Watch - ColorPickerUI: localColor =", newValue);
      this.$emit("updateColor", newValue); // Emit event to update color in parent
    }
  }
};
</script>

<style scoped>
/* Custom styling for the slider if necessary */
</style>
