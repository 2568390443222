<template>
  <div>
    <div class="form-group card">
      <div class="card-header text-dark">
        Production Data File Upload
        <div
          v-if="localStatusObj.progress"
          class="spinner-border spinner-border-sm text-primary"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <span v-if="localStatusObj.errorMessage" class="mx-1 text-danger">
          {{ localStatusObj.errorMessage }}
        </span>
        <span v-if="localStatusObj.message" class="mx-1 text-info">
          {{ localStatusObj.message }}
        </span>
        <span
          v-if="uploadMsgObj && uploadMsgObj.dataset_inserted"
          class="mx-1 text-primary"
        >
          Inserted data: {{ uploadMsgObj.dataset_inserted }}
        </span>
        <span
          v-if="uploadMsgObj && uploadMsgObj.warnings"
          class="mx-1 text-warning"
        >
          Warnings: {{ uploadMsgObj.warnings }}
        </span>
        <!-- <div>
          {{ uploadMsgObj }}
        </div> -->
      </div>
      <div class="card-body">
        <div class="row">
          <div class="form-group col-md-6">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="fileUploadRadios"
                id="fillinsRadio"
                value="fillins"
                v-model="fileUploadType"
              />
              <label class="form-check-label" for="fillinsRadio">
                fill-ins
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="fileUploadRadios"
                id="machinesRadio"
                value="machines"
                v-model="fileUploadType"
              />
              <label class="form-check-label" for="machinesRadio">
                machines
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="fileUploadRadios"
                id="mstsRadio"
                value="measurements"
                v-model="fileUploadType"
              />
              <label class="form-check-label" for="mstsRadio">
                measurements
              </label>
            </div>
          </div>
          <div class="form-group col-md-6">
            <input
              id="inputCSVFile"
              type="file"
              accept=".csv"
              :disabled="uploadMsgObj"
              v-on:click="clearInputCSVFile"
              v-on:change="changeFile($event, culture, 'CSV')"
            />
            <span v-if="selectedFile">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                :disabled="previewDatas"
                v-on:click="uploadData()"
              >
                Upload
              </button>
            </span>
          </div>
        </div>
        <div
          v-if="uploadMsgObj && uploadMsgObj.dataset_inserted > 0"
          class="my-1"
        >
          <label class="form-control-label mr-2">{{ previewLabel }}</label>
          <button
            type="button"
            class="btn btn-primary btn-sm"
            :disabled="previewDatas"
            v-on:click="previewdData()"
          >
            Preview
          </button>
        </div>
        <div>
          <div v-if="previewDatas" class="py-1">
            <div class="card">
              <div class="card-header text-dark">Loaded Product Data</div>
              <div class="card-body">
                <div class="table-responsive">
                  <table
                    v-if="category === 'vitk2_fillins'"
                    class="table table-striped table-sm"
                  >
                    <thead>
                      <tr>
                        <th class="small" scope="col">#</th>
                        <th class="small" scope="col">Time (h)</th>
                        <th class="small" scope="col">Alkali pump</th>
                        <th class="small" scope="col">Feed pump</th>
                        <th class="small" scope="col">Air (L/min)</th>
                        <th class="small" scope="col">Feed.Sol.Density</th>
                        <th class="small" scope="col">Feed.Sol.Conc. (g/L)</th>
                        <th class="small" scope="col">Fed.Feed.Sol.Vol. (L)</th>
                        <th class="small" scope="col">Working Vol. (L)</th>
                        <th class="small" scope="col">Sample.Vol. (mL)</th>
                        <th class="small" scope="col">In.Gly.Conc. (g/L)</th>
                        <th class="small" scope="col">
                          Sp.Int.Yield (mg VK2/g DCW/g Glycerol)
                        </th>
                        <th class="small" scope="col">
                          Sp.Cum.Yield (mg Vk2/g DCW/g Glycerol)
                        </th>
                        <th class="small" scope="col">
                          Sp.Int.Gly.Up.Rate (g Glycerol/g DCW/h)
                        </th>
                        <th class="small" scope="col">
                          Sp.Cum.Gly.Up.Rate (g Glycerol/g DCW/h)
                        </th>
                        <th class="small" scope="col">
                          Sp.Int.VK2.Prod.Rate (mg VK2/g DCW/h)
                        </th>
                        <th class="small" scope="col">
                          Sp.Cum.VK2.Prod.Rate (mg VK2/g DCW/h)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(pData, index) in previewDatas" :key="index">
                        <th class="small" scope="row">{{ index + 1 }}</th>
                        <td class="small">{{ pData.time }}</td>
                        <td class="small">{{ pData.alkali_pump }}</td>
                        <td class="small">{{ pData.feed_pump }}</td>
                        <td class="small">{{ pData.air }}</td>
                        <td class="small">{{ pData.feeding_sol_dens }}</td>
                        <td class="small">{{ pData.feeding_sol_conc }}</td>
                        <td class="small">
                          {{ pData.fed_feeding_sol_vol }}
                        </td>
                        <td class="small">{{ pData.working_vol }}</td>
                        <td class="small">{{ pData.sampling_vol }}</td>
                        <td class="small">{{ pData.input_glycerol_conc }}</td>
                        <td class="small">
                          {{ pData.specific_int_yield }}
                        </td>
                        <td class="small">{{ pData.specific_cum_yield }}</td>
                        <td class="small">
                          {{ pData.specific_int_glycerol_uprate }}
                        </td>
                        <td class="small">
                          {{ pData.specific_cum_glycerol_uprate }}
                        </td>
                        <td class="small">
                          {{ pData.specific_int_vit_prodrate }}
                        </td>
                        <td class="small">
                          {{ pData.specific_cum_vit_prodrate }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    v-if="category === 'vitk2_machines'"
                    class="table table-striped table-sm"
                  >
                    <thead>
                      <tr>
                        <th class="small" scope="col">#</th>
                        <th class="small" scope="col">Time (h)</th>
                        <th class="small" scope="col">pH</th>
                        <th class="small" scope="col">DO</th>
                        <th class="small" scope="col">CO2 (%)</th>
                        <th class="small" scope="col">O2 (%)</th>
                        <th class="small" scope="col">Feed.Sol.Wt. (g)</th>
                        <th class="small" scope="col">RPM</th>
                        <th class="small" scope="col">Temp. (oC)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(pData, index) in previewDatas" :key="index">
                        <th class="small" scope="row">{{ index + 1 }}</th>
                        <td class="small">{{ pData.time }}</td>
                        <td class="small">{{ pData.ph_control }}</td>
                        <td class="small">{{ pData.do_control }}</td>
                        <td class="small">{{ pData.co2_control }}</td>
                        <td class="small">{{ pData.o2_control }}</td>
                        <td class="small">{{ pData.feeding_sol_weight }}</td>
                        <td class="small">{{ pData.rpm_control }}</td>
                        <td class="small">{{ pData.temp }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    v-if="category === 'vitk2_measurements'"
                    class="table table-striped table-sm"
                  >
                    <thead>
                      <tr>
                        <th class="small" scope="col">#</th>
                        <th class="small" scope="col">Time (h)</th>
                        <th class="small" scope="col">OD (600nm)</th>
                        <th class="small" scope="col">Glycerol conc. (g/L)</th>
                        <th class="small" scope="col">VK2 conc. (mg/L)</th>
                        <th class="small" scope="col">
                          Acetic acid conc. (g/L)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(pData, index) in previewDatas" :key="index">
                        <th class="small" scope="row">{{ index + 1 }}</th>
                        <td class="small">{{ pData.time }}</td>
                        <td class="small">{{ pData.od_600nm }}</td>
                        <td class="small">{{ pData.glycerol_conc }}</td>
                        <td class="small">{{ pData.vk2_conc }}</td>
                        <td class="small">{{ pData.acetic_acid_conc }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="mt-2 float-right">
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    v-on:click="clearPreviewData"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- .card-body -->
      <div class="card-footer text-center">
        <span>Download Production data CSV template:</span>
        <span class="px-1">
          <a :href="templateCSV" target="_blank" class="card-link" download>
            {{ templateFileName }}
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";
import { OlabSearch } from "@/olab/olabsearch";
import { OlabProdDataVitk2 } from "@/olab/olabproddatavitk2";

export default {
  name: "FileUpload",
  data() {
    return {
      fileUploadType: "measurements",
      uploadMsgObj: null,
      previewDatas: null,
      selectedFile: null,
      category: "",
      // TODO: Add status alert later
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      }
    };
  },
  props: ["user", "exprt", "culture"],
  async mounted() {},
  computed: {
    previewLabel() {
      let label = "Preview up to last 20 rows:";
      if (
        this.uploadMsgObj &&
        this.uploadMsgObj.dataset_inserted > 0 &&
        this.uploadMsgObj.dataset_inserted < 20
      ) {
        label = `Preview uploaded ${this.uploadMsgObj.dataset_inserted} rows :`;
      }
      return label;
    },
    templateFileName() {
      let fName = "NO NAME";
      if (this.fileUploadType === "fillins") {
        fName = "vitk2_fillins";
      } else if (this.fileUploadType === "machines") {
        fName = "vitk2_machines";
      } else if (this.fileUploadType === "measurements") {
        fName = "vitk2_measurements";
      }
      return fName;
    },
    templateCSV() {
      let fName = "NO NAME";
      if (this.fileUploadType === "fillins") {
        fName = "prod_data_vitk2_fillins";
      } else if (this.fileUploadType === "machines") {
        fName = "prod_data_vitk2_machines";
      } else if (this.fileUploadType === "measurements") {
        fName = "prod_data_vitk2_measurements";
      }
      const csvFile = "templates/" + fName + ".csv";
      // console.log("computed CSV file =", csvFile);
      return csvFile;
    }
  },
  methods: {
    clearInputCSVFile() {
      // Clear inputCSVFile
      // console.log("Clear inputCSVFile");
      const csvFileHandle = document.getElementById("inputCSVFile");
      if (csvFileHandle) {
        csvFileHandle.value = "";
      }
      // Reset localStatusObj
      this.localStatusObj.message = null;
      this.localStatusObj.progress = false;
      this.localStatusObj.searching = false;
      this.localStatusObj.errorMessage = null;
    },
    // Upload Production Data
    async changeFile(e, cltr, fileType) {
      // console.log("changeFile e =", e.target);
      if (fileType === "CSV") {
        this.selectedFile = e.target.files[0];
        if (this.selectedFile) {
          cltr.csv_file = this.selectedFile;
        } else {
          cltr.csv_file = null;
          this.selectedFile = null;
          this.uploadMsgObj = null;
        }
      }
    },
    clearPreviewData() {
      this.previewDatas = null;
      this.uploadMsgObj = null;
      this.clearInputCSVFile();
    },
    async previewdData() {
      // Possible types are "bioreactor_pd", "flask_pd" and "tube_pd"
      // console.log("previewData: culture =", this.culture);
      let sType = "bioreactor_pd";
      if (this.culture) {
        sType = this.culture.container_type + "_pd";
      }
      const searchBody = OlabSearch.createSearchBody(
        sType,
        this.uploadMsgObj.origin_csv,
        "origin_csv"
      );
      // Need to set category for different data (fillins, machines, meansurement)
      this.category = `${this.culture.prod_data_category}_${this.fileUploadType}`;
      // console.log("category =", this.category);
      this.previewDatas = await OlabProdDataVitk2.getProductionDatas(
        this.category,
        searchBody,
        this.localStatusObj
      );
      // console.log("previewDatas =", this.previewDatas);
    },
    async uploadData() {
      // console.log("uploadData: fileUploadType =", this.fileUploadType);
      // console.log("uploadData: culture =", this.culture);

      // Need to set category for different data (fillins, machines, meansurement)
      const category = `${this.culture.prod_data_category}_${this.fileUploadType}`;
      // console.log("category =", category);

      this.uploadMsgObj = await OlabAxios.updateCultureProductionData(
        this.user,
        this.culture,
        category,
        this.localStatusObj
      );
      // console.log("(1) uploadData: uploadMsgObj =", this.uploadMsgObj);

      // Reset selectedFil after done uploading file
      this.selectedFile = null;
      // Request a reload of upload in ProductionData
      this.$emit("setReloadUpload", true);
      // console.log("(2) uploadData: uploadMsgObj =", this.uploadMsgObj);
    }
  },
  watch: {
    "culture.olab_id"() {
      // console.log("watch: culture =", this.culture);
      this.clearPreviewData();
    }
  }
};
</script>
