<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="selectedType === 'experiment'">
    <!-- <div v-if="dbInfo">
      {{ dbInfo }}
    </div> -->
    <form v-on:submit.prevent="onSubmit">
      <div class="form-group">
        <label for="inp-exprt-name" class="form-control-label">
          <span>Name:</span>
        </label>
        <input
          type="text"
          class="form-control"
          v-bind:class="validState(item.name)"
          id="inp-exprt-name"
          placeholder="Name"
          v-model="item.name"
        />
      </div>
      <div class="form-row">
        <div class="form-group col-md-10">
          <label for="sel-branch">Branch:</label>
          <span v-if="branch == null" class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div class="mb-2">
            <VueMultiselect
              id="sel-branch"
              v-model="branch"
              :options="branchs"
              :loading="isLoadingProj"
              :internal-search="false"
              :multiple="false"
              :close-on-select="true"
              :options-limit="10"
              :custom-label="nameInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
              @search-change="getBranchs"
            />
          </div>
          <!-- <p v-if="branch">
            {{ branch.olab_id }}
          </p> -->
        </div>
      </div>
      <div class="form-row">
        <div v-if="categories" class="form-group col-md-4">
          <label for="sel-catorgory">Category:</label>
          <span
            v-if="category === null"
            class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div class="mb-2">
            <VueMultiselect
              id="sel-catorgory"
              v-model="category"
              :options="categories"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              :allow-empty="true"
              deselectLabel=""
              :showLabels="false"
              label="display_name"
              track-by="key_name"
            />
          </div>
          <!-- <p v-if="category">{{ category }}</p> -->
        </div>
        <div class="form-group col-md-4">
          <label for="sel-num-culture-stages">Number Of Stages:</label>
          <span
            v-if="numCultureStages == null"
            class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div class="mb-2">
            <VueMultiselect
              id="sel-num-culture-stages"
              v-model="numCultureStages"
              :options="numCultureStagesSettingModel"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              :allow-empty="true"
              deselectLabel=""
              :showLabels="false"
              label="display_name"
              track-by="key_name"
            />
          </div>
          <!-- <p v-if="numCultureStages">{{ numCultureStages }}</p> -->
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inp-start-date" class="form-control-label">
            <span>Start Date:</span>
          </label>
          <input
            type="date"
            class="form-control"
            v-bind:class="validState(item.start_date)"
            id="inp-start-date"
            placeholder="Start Date"
            v-model="item.start_date"
          />
        </div>
        <div class="form-group col-md-4">
          <label for="inp-end-date" class="form-control-label">
            <span>End Date:</span>
          </label>
          <input
            type="date"
            class="form-control"
            id="inp-end-date"
            placeholder="End Date"
            v-model="item.end_date"
          />
        </div>
      </div>
      <!-- Hypothesis component -->
      <HypothesisAdd
        :user="user"
        :item="item"
        prepLoad="false"
        :resetItem="resetItem"
        @copyHypothesis="copyHypothesis"
      />
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="sel-control-strain">Control Strain:</label>
          <span
            v-if="controlStrain == null"
            class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div>
            <VueMultiselect
              id="sel-control-strain"
              v-model="controlStrain"
              :options="strains"
              :loading="isLoadingCStrn"
              :internal-search="false"
              :multiple="false"
              :close-on-select="true"
              :options-limit="10"
              :custom-label="nameInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
              @search-change="getCStrains"
            />
          </div>
          <!-- <p v-if="controlStrain">
            {{ controlStrain.olab_id }}
          </p> -->
        </div>
        <div class="form-group col-md-6">
          <label for="sel-test-strains">Test Strain(s):</label>
          <span
            v-if="testStrains == null"
            class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div>
            <VueMultiselect
              id="sel-test-strains"
              v-model="testStrains"
              :options="strains"
              :loading="isLoadingTStrn"
              :internal-search="false"
              :multiple="true"
              :close-on-select="true"
              :options-limit="10"
              :custom-label="nameInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
              @search-change="getTStrains"
            />
          </div>
          <!-- <p v-if="testStrains">
            {{ testStrains }}
          </p> -->
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="sel-related-expts">Related Experiment(s)</label>
          <div>
            <VueMultiselect
              id="sel-related-expts"
              v-model="relatedExprts"
              :options="experiments"
              :loading="isLoadingExprt"
              :internal-search="false"
              :multiple="true"
              :close-on-select="true"
              :options-limit="10"
              :custom-label="nameInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
              @search-change="getExperiments"
            />
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="sel-verifier">Verifier:</label>
          <div>
            <VueMultiselect
              id="sel-verifier"
              v-model="verifier"
              :options="users"
              :loading="isLoadingVerfr"
              :internal-search="false"
              :multiple="false"
              :close-on-select="true"
              placeholder="Select a Verifier ..."
              :options-limit="10"
              :custom-label="verifierInfo"
              label="olab_id"
              track-by="olab_id"
              @search-change="getVerifiers"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inp-verifier-sign" class="form-control-label">
            <span>Verifier Signature:</span>
          </label>
          <input
            id="inp-verifier-sign"
            class="form-control"
            type="text"
            placeholder="Verifier Signature"
            :disabled="!verifier || verifier.email !== user.email"
          />
        </div>
        <div class="form-group col-md-4">
          <label for="inp-date-verified" class="form-control-label">
            <span>Date:</span>
          </label>
          <input
            id="inp-date-verified"
            class="form-control"
            type="date"
            placeholder="Verified Date"
            :disabled="!verifier || verifier.email !== user.email"
          />
        </div>
      </div>
      <div class="form-group">
        <label for="inp-desc">Description</label>
        <textarea
          type="textarea"
          class="form-control"
          v-model="item.desc"
          id="inp-desc"
        ></textarea>
      </div>
      <div class="form-group">
        <label for="inp-note">Note</label>
        <textarea
          type="textarea"
          class="form-control"
          v-model="item.note"
          id="inp-note"
        ></textarea>
      </div>
      <div class="form-group float-right">Created by: {{ item.creator }}</div>
    </form>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";
import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";
import { OlabSearch } from "@/olab/olabsearch";
import VueMultiselect from "vue-multiselect";
import HypothesisAdd from "@/components/forms/HypothesisAdd";
import { numCultureStagesSettingModel } from "@/assets/data/culture_stage_setting";

export default {
  name: "ExperimentAdd",
  data() {
    return {
      numCultureStagesSettingModel,
      invalidFB: OlabItem.getInvalidFeedbacks(),
      branch: null,
      category: null,
      categories: null,
      numCultureStages: null,
      controlStrain: null,
      testStrains: null,
      relatedExprts: null,
      verifier: null,
      experiments: [],
      branchs: [],
      strains: [],
      users: [],
      isLoadingProj: false,
      isLoadingCStrn: false,
      isLoadingTStrn: false,
      isLoadingExprt: false,
      isLoadingVerfr: false,
      hypotheses: [
        {
          statement: "",
          cause: "",
          effect: "",
          answered: "N/A"
        },
        {
          statement: "",
          cause: "",
          effect: "",
          answered: "N/A"
        },
        {
          statement: "",
          cause: "",
          effect: "",
          answered: "N/A"
        }
      ]
    };
  },
  components: {
    VueMultiselect,
    HypothesisAdd
  },
  props: ["user", "dbInfo", "selectedType", "item", "resetItem", "statusObj"],
  emits: ["clearResetItem"],
  async mounted() {
    await this.getExperiments("");
    await this.getBranchs("");
    await this.getCStrains("");
    // await this.getTStrains("");
    await this.getVerifiers("");

    // Set default numCultureStages to 3
    if (this.numCultureStagesSettingModel) {
      this.numCultureStages = this.numCultureStagesSettingModel.find(
        (tt) => tt.key_name === 3
      );
    }
    // Create categories listing
    if (this.dbInfo && this.dbInfo.prod_data_categories) {
      const pdc = this.dbInfo.prod_data_categories;
      this.categories = [];
      pdc.forEach((catName) => {
        const cat = {
          key_name: catName,
          display_name: catName
        };
        this.categories.push(cat);
      });
    }
  },
  methods: {
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    nameInfo({ olab_id, name }) {
      return `${name}: [${olab_id}]`;
    },
    verifierInfo({ name, email }) {
      return `${name} - ${email}`;
    },
    async getExperiments(query) {
      // console.log("getExperiments: query = ", query);
      this.isLoadingExprt = true;
      const searchBody = OlabSearch.createSearchBody(
        "experiment",
        query,
        "merge"
      );
      this.experiments = await OlabAxios.getExperiments(
        searchBody,
        this.statusObj
      );
      this.isLoadingExprt = false;
    },
    async getBranchs(query) {
      // console.log("getBranchs: query = ", query);
      this.isLoadingProj = true;
      const searchBody = OlabSearch.createSearchBody("branch", query, "merge");
      this.branchs = await OlabAxios.getBranchs(searchBody, this.statusObj);
      this.isLoadingProj = false;
    },
    async getCStrains(query) {
      // console.log("getCStrains: query = ", query);
      this.isLoadingCStrn = true;
      const searchBody = OlabSearch.createSearchBody("strain", query, "merge");
      this.strains = await OlabAxios.getStrains(searchBody, this.statusObj);
      this.isLoadingCStrn = false;
    },
    async getTStrains(query) {
      // console.log("getTStrains: query = ", query);
      this.isLoadingTStrn = true;
      const searchBody = OlabSearch.createSearchBody("strain", query, "merge");
      this.strains = await OlabAxios.getStrains(searchBody, this.statusObj);
      this.isLoadingTStrn = false;
    },
    async getVerifiers(query) {
      // console.log("getVerifiers: query = ", query);
      this.isLoadingVerfr = true;
      const searchBody = OlabSearch.createSearchBody("user", query, "merge");
      this.users = await OlabAxios.getUsers(searchBody, this.statusObj);
      this.isLoadingVerfr = false;
    },
    copyHypothesis(idx, hypo) {
      // console.log("** copyHypothesis: idx =", idx, ", ", hypo);
      if (idx >= 0 && idx < 3 && hypo) {
        this.hypotheses[idx].statement = hypo.statement;
        this.hypotheses[idx].cause = hypo.cause;
        this.hypotheses[idx].effect = hypo.effect;
        this.hypotheses[idx].answered = hypo.answered;
      }
      // console.log(this.hypotheses[idx]);
      this.item.setHypotheses(this.hypotheses);
    }
  },
  computed: {},
  watch: {
    relatedExprts() {
      // console.log("relatedExprts =", this.relatedExprts);
      const tmpReExprtIDs = [];
      if (this.relatedExprts && this.relatedExprts.length > 0) {
        this.relatedExprts.forEach((re) => {
          tmpReExprtIDs.push(re.olab_id);
        });
      }
      // console.log("tmpStrnIDs =", tmpStrnIDs);
      this.item.setRelatedExprts(tmpReExprtIDs);
    },
    branch() {
      this.item.setBranch(this.branch);
    },
    category() {
      const cat =
        this.category && this.category.key_name ? this.category.key_name : null;
      this.item.setCategory(cat);
      // console.log("watch: category - cat =", cat, ", item =", this.item);
    },
    numCultureStages() {
      const ncs =
        this.numCultureStages && this.numCultureStages.key_name
          ? this.numCultureStages.key_name
          : 0;
      this.item.setNumCultureStages(ncs);
    },
    controlStrain() {
      this.item.setControlStrain(this.controlStrain);
    },
    testStrains() {
      // console.log("testStrains =", this.testStrains);
      const tmpStrnIDs = [];
      if (this.testStrains && this.testStrains.length > 0) {
        this.testStrains.forEach((ts) => {
          tmpStrnIDs.push(ts.olab_id);
        });
      }
      // console.log("tmpStrnIDs =", tmpStrnIDs);
      this.item.setTestStrains(tmpStrnIDs);
    },
    verifier() {
      this.item.setVerifier(this.verifier);
    },
    resetItem() {
      // Clean previously set fields once add is excuted
      // console.log("ExperimentAdd:resetItem() - olab_id =", this.item.olab_id);
      this.branch = null;
      this.numCultureStages = null;
      this.controlStrain = null;
      this.testStrains = null;
      this.relatedExprts = null;
      this.verifier = null;
      // this.experiments = [];
      // this.branchs = [];
      // this.strains = [];
      // this.users = [];
      this.$emit("clearResetItem");
    }
  }
};
</script>
