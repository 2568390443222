export const faqdata = [
  {
    olab_id: "FAQ-0001",
    module: "database",
    tags: [],
    question: "How to find Database",
    answer: "Click the Database module at the top right menu of the page.",
    sections: [
      {
        id: 1,
        image: "faq-databaseMod.svg",
        description: "Locate the Database module located at the top right menu."
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0002",
    module: "experiment",
    tags: [],
    question:
      "How do I create data under ‘Culture’ or ‘Experiment’ under the Database section?",
    answer:
      "Data for ‘culture’ & ‘experiment’ is automatically generated when an Experiment is added.",
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0003",
    module: "experiment",
    tags: [],
    question: "How to add a NEW experiment",
    answer:
      "Click the Experiment module located at the top right menu. Then locate the '+' button on the top left side of the search bar.",
    sections: [
      {
        id: 1,
        image: "faq-experimentMod.svg",
        description:
          "Click the 'Experiment' module located at the top right menu."
      },
      {
        id: 2,
        image: "faq-0003_1.svg",
        description:
          "Then locate the '+' button on the top left side of the search bar."
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0004",
    module: "experiment",
    tags: ["culture"],
    question: "How do I add cultures to an experiment",
    answer:
      "An experiment must either be added first or already existing. Only existing experiments can have additional data added on by selecting the checkbox of the desired experiment and then moving onto filling in information such as ‘inital culture’, ‘second culture’, ‘production’, etc..",
    sections: [
      {
        id: 1,
        image: "faq-experimentMod.svg",
        description:
          "Click the 'Experiment' module located at the top right menu."
      },
      {
        id: 2,
        image: "faq-experimentSel.svg",
        description:
          "Select the existing Experiment to which you wish to add a culture"
      },
      {
        id: 3,
        image: "faq-0004_1.svg",
        description:
          "Select the '+' next to 'Initial Culture' and/or subsequent 'Second Culture' or 'Production'"
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0005",
    module: "experiment",
    tags: ["view", "edit", "culture"],
    question: "How do I view/edit existing cultures of an Experiment",
    answer:
      "Select the checkbox of an existing experiment. The ability to view & edit the cultures for that experiment should populate.",
    sections: [
      {
        id: 1,
        image: "faq-experimentMod.svg",
        description:
          "Click the 'Experiment' module located at the top right menu."
      },
      {
        id: 2,
        image: "faq-experimentSel.svg",
        description:
          "Select the existing Experiment to which you wish to edit or view cultures"
      },
      {
        id: 3,
        image: "faq-0005_1.svg",
        description:
          "Select the dropdown menu under 'Initial', 'Second', or 'Production' to view and choose an existing culture."
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0006",
    module: "experiment",
    tags: ["data", "production"],
    question: "How do I upload production data",
    answer:
      "Production data (csv file) can be only uploaded to the Production culture. The corresponding production culture needs to be selected first and choose the csv file in order to upload it",
    sections: [
      {
        id: 1,
        image: "faq-experimentMod.svg",
        description:
          "Click the 'Experiment' module located at the top right menu."
      },
      {
        id: 2,
        image: "faq-experimentSel.svg",
        description: "Select the existing Experiment you wish to use"
      },
      {
        id: 3,
        image: "faq-0006_1.svg",
        description:
          "Ensure that you have an existing culture to select under Production Culture."
      },
      {
        id: 4,
        image: "faq-0006_2.svg",
        description: "Scroll down to Production Upload."
      },
      {
        id: 5,
        image: "faq-0006_3.svg",
        description:
          "Choose a file and upload it. File must be formatted according to CSV template."
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0007",
    module: "experiment",
    tags: ["experiment", "culture"],
    question: "Where do I find the culture I created",
    answer:
      "When a culture is created within a stage of an Experiment, that culture is only able to be used within that stage of that specific Experiment. It cannot be used in other stages of that Experiment or in other Experiments.",
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },

  {
    olab_id: "FAQ-0008",
    module: "chart",
    tags: ["create"],
    question: "How do I create a chart",
    answer:
      "Click the Chart module located at the top right menu. Then locate the '+' button on the top left side of the search bar.",
    sections: [
      {
        id: 1,
        image: "faq-chartMod.svg",
        description: "Locate the 'Chart' module located at the top right menu."
      },
      {
        id: 2,
        image: "faq-chartAdd.svg",
        description:
          "Then locate the '+' button on the top left side of the search bar."
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0009",
    module: "database",
    tags: ["create", "project"],
    question: "How do I create a project",
    answer:
      "Click the Database module located at the top right menu. Then click the dropdown menu on the top left menu. Select the Project option in the menu. To add a new project simply click the '+' button next to it.",
    sections: [
      {
        id: 1,
        image: "faq-databaseMod.svg",
        description: "Locate Database module in the top right main navigation"
      },
      {
        id: 2,
        image: "faq-databaseSubnav.svg",
        description: "Find the dropdown menu for the subdirectories"
      },
      {
        id: 3,
        image: "faq-subNavProject.svg",
        description: "Click on 'Project'"
      },
      {
        id: 4,
        image: "faq-0009_1.svg",
        description: "Located the '+' button on the top left menu"
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0010",
    module: "database",
    tags: ["branch"],
    question: "What is a branch",
    answer:
      "A Branch is a sub-project (or a task) of Project. A Project must be created before a Branch.",
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0011",
    module: "database",
    tags: ["create", "branch"],
    question: "How do I create a branch",
    answer:
      "Click the 'Database' module in top right main navigation. Then click the dropdown menu on the left. Select the Branch option in the menu. To add a new branch simply click the '+' button next to it.",
    sections: [
      {
        id: 1,
        image: "faq-databaseMod.svg",
        description: "Click Database module in the top right main navigation"
      },
      {
        id: 2,
        image: "faq-databaseSubnav.svg",
        description: "Click the dropdown menu on the left"
      },
      {
        id: 3,
        image: "faq-0011_1.svg",
        description: "Go to 'Branch'"
      },
      {
        id: 4,
        image: "faq-0011_2.svg",
        description: "Click '+' on the top left menu"
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0012",
    module: "database",
    tags: ["project"],
    question: "How can I change my private project to division viewable?",
    answer:
      "Click 'Database' module in top right main navigation. Then click the dropdown menu on the left. Select the Project option in the menu. Search for the project in the list. Click the edit button of the selected project. Lastly locate and check the Division Access checkbox.",
    sections: [
      {
        id: 1,
        image: "faq-databaseMod.svg",
        description: "Click Database module in the top right main navigation."
      },
      {
        id: 2,
        image: "faq-databaseSubnav.svg",
        description: "Find the dropdown menu on the left."
      },
      {
        id: 3,
        image: "faq-subNavProject.svg",
        description: "Go to Project."
      },
      {
        id: 4,
        image: "faq-0012_1.svg",
        description: "Click an existing project to edit."
      },
      {
        id: 5,
        image: "faq-0012_2.svg",
        description:
          "Check or Uncheck 'Division Access' checkbox to manage access of users."
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0013",
    module: "database",
    tags: ["user", "edit", "create"],
    question: "What components can a user edit and create",
    answer:
      "A User can only edit and change data within the Database regarding Experiments that they created themselves. Every other component is only viewable to the User.",
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0014",
    module: "chart",
    tags: ["chart", "plot"],
    question: "How do I create a chart before configuring the plot",
    answer:
      "Go to Chart in the top menu. Click ‘+’ to create a new chart. Enter Name and select Project. Click Add. The newly created chart title willpopulate at the top. Click the box on the left to start plot configuration.",
    sections: [
      {
        id: 1,
        image: "faq-chartMod.svg",
        description: "Click Chart module in the top right main navigation."
      },
      {
        id: 2,
        image: "faq-chartAdd.svg",
        description: "Click '+' on the top left menu"
      },
      {
        id: 3,
        image: "faq-0014_1.svg",
        description: "Fill in form to create new Chart and click Add."
      },
      {
        id: 4,
        image: "faq-0014_2.svg",
        description:
          "Your newly created chart should populate at the top. Select the box on the left of that Chart."
      },
      {
        id: 5,
        image: "faq-0014_3.svg",
        description: "Begin plot configuration."
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0015",
    module: "chart",
    tags: ["chart", "plot"],
    question:
      "How do I configure a chart to see the performance of the cultures tested in the experiment, i.e. New test",
    answer:
      "Click Chart module in the top right main navigation. Search for the desired Chart in search bar. Select the box on the left of the desired Chart. Select the chart type, Line, is set as a default. Select Experiment in Filters to make Experiment selectable. Search and select New test under Experiment. Select Culture in Group By to see all the cultures separately. Select X Axes. Select Left Y Axes (multiple parameters can be chosen if they use the same units). The chart will be created automatically. Scroll down to see.",
    sections: [
      {
        id: 1,
        image: "faq-chartMod.svg",
        description: "Click Chart module in the top right main navigation."
      },
      {
        id: 2,
        image: "faq-chartSearch.svg",
        description: "Search for the desired Chart in search bar."
      },
      {
        id: 3,
        image: "faq-chartSel.svg",
        description: "Select the box on the left of the desired Chart."
      },
      {
        id: 4,
        image: "faq-0015_1.svg",
        description:
          "Select the chart type, Line, set as a default. Select Experiment in Filters to make Experiment selectable. Search and select New test under Experiment. Select Culture in Group By to see all the cultures separately. Select X Axes. Select Left Y Axes (multiple parameters can be chosen if they use the same units)."
      },
      {
        id: 5,
        image: "faq-0015_2.svg",
        description:
          "The chart will be created automatically. Scroll down to see."
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0016",
    module: "chart",
    tags: ["chart", "plot"],
    question:
      "How do I configure a chart to see the performance of strain YK-1 in all the experiments",
    answer:
      "Click Chart module in the top right main navigation. Search for the desired Chart in search bar. Select the box on the left of the desired Chart. Select the chart type, Line, is set as a default. Select Strain in Filters to make strains selectable. Search and select YK-1 in Strain. Select X Axes. Select Left Y Axes: multiple parameters can be chosen if they use the same units. The chart will be created automatically. Scroll down to see.",
    sections: [
      {
        id: 1,
        image: "faq-chartMod.svg",
        description: "Click Chart module in the top right main navigation."
      },
      {
        id: 2,
        image: "faq-chartSearch.svg",
        description: "Search for the desired Chart in search bar."
      },
      {
        id: 3,
        image: "faq-chartSel.svg",
        description: "Select the box on the left of the desired Chart."
      },
      {
        id: 4,
        image: "faq-0016_1.svg",
        description:
          "Select the chart type, Line, set as a default. Select Strain in Filters to make strains selectable. Search and select YK-1 in Strain. Select X Axes. Select Left Y Axes: multiple parameters can be chosen if they use the same units."
      },
      {
        id: 5,
        image: "faq-0016_2.svg",
        description:
          "The chart will be created automatically. Scroll down to see."
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0017",
    module: "chart",
    tags: ["chart", "plot"],
    question:
      "How do I configure a chart to see the performance of strain YK-1 in the selected experiments only",
    answer:
      "Click Chart module in the top right main navigation. Search for the desired Chart in search bar. Select the box on the left of the desired Chart. Select the chart type, Line, set as a default. Select Strain and Experiment in Filters to make strain and experiment selectable. Search and select the experiments of interest. Search and select YK-1 in Strain. Select X Axes. The chart will be created automatically. Scroll down to see.",
    sections: [
      {
        id: 1,
        image: "faq-chartMod.svg",
        description: "Click Chart module in the top right main navigation."
      },
      {
        id: 2,
        image: "faq-chartSearch.svg",
        description: "Search for the desired Chart in search bar."
      },
      {
        id: 3,
        image: "faq-chartSel.svg",
        description: "Select the box on the left of the desired Chart."
      },
      {
        id: 4,
        image: "faq-0017_1.svg",
        description:
          "Select the chart type, Line, set as a default. Select Strain and Experiment in Filters to make strain and experiment selectable. Search and select the experiments of interest. Search and select YK-1 in Strain. Select X Axes."
      },
      {
        id: 5,
        image: "faq-0017_2.svg",
        description:
          "The chart will be created automatically. Scroll down to see."
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0018",
    module: "database",
    tags: ["chemical, database"],
    question:
      "How do I enter ‘500g Sodium Chloride (NaCl)' from Sigma (Catalog#0001, Lot# 0001) in the database",
    answer:
      "Click 'Database' module in top right main navigation. Then click the dropdown menu on the left. Select the Chemical option in the menu. Click '+' on the top left to open entry form. Fill out the entry form. Name and Manufacturer ID are required. It is recommended to enter other information (if available).",
    sections: [
      {
        id: 1,
        image: "faq-databaseMod.svg",
        description:
          "Click Database module in the top right main navigation.(It is recommended to check if Sigma is already registered in Manufacturer database. Or, Manufacturer list can be seen in the Manufacturer section in the chemical entry form)"
      },
      {
        id: 2,
        image: "faq-databaseSubnav.svg",
        description: "Find the dropdown menu on the left."
      },
      {
        id: 3,
        image: "faq-subNavChemical.svg",
        description: "Go to Chemical."
      },
      {
        id: 4,
        image: "faq-0018_1.svg",
        description: "Click ‘+’ to open the entry form."
      },
      {
        id: 5,
        image: "faq-0018_2.svg",
        description:
          "Fill out the entry form. Name and Manufacturer ID are required. It is recommended to enter other information (if available)."
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0019",
    module: "database",
    tags: ["media, database"],
    question: "How do I create a media in the database",
    answer:
      "Click Database module in the top right main navigation. Go to Media. Click ‘+’ to open the entry form. Fill out the entry form. Boxes in red are required. It is recommended to enter other information (if available). Select the media recipe from the dropdown menu. Enter the ‘Media Volume’. It will automatically calculate required ingredients for you. Fill out the purpose. By entering the Media Volume, required ingredients will be automatically calculated for you. Click Add.",
    sections: [
      {
        id: 1,
        image: "faq-databaseMod.svg",
        description: "Click Database module in the top right main navigation."
      },
      {
        id: 2,
        image: "faq-databaseSubnav.svg",
        description: "Find the dropdown menu on the left."
      },
      {
        id: 3,
        image: "faq-subNavMedia.svg",
        description: "Go to Media."
      },
      {
        id: 4,
        image: "faq-0019_1.svg",
        description: "Click ‘+’ to open the entry form."
      },
      {
        id: 5,
        image: "faq-0019_2.svg",
        description:
          "Fill out the entry form. It is recommended to enter other information (if available)."
      },
      {
        id: 6,
        image: "faq-0019_3.svg",
        description: "Select the media recipe from the dropdown menu."
      },
      {
        id: 7,
        image: "faq-0019_4.svg",
        description: "Enter the Media Volume. Fill out the purpose."
      },
      {
        id: 8,
        image: "faq-0019_5.svg",
        description:
          "By entering the Media Volume, required ingredients will be automatically calculated for you. Click Add."
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0020",
    module: "experiment",
    tags: ["culture, experiment"],
    question:
      "How do I enter multiple cultures for the same conditions in Experiment",
    answer:
      "Click Experiment module in the top right main navigation. Check the box of the experiment of your interest. Click ‘+’ beside Initial Culture to open the entry form. Check ‘Fill with existing culture’ if you want to enter the same condition as the culture already present. Click and Select the ‘Existing culture’. Click and Select the strain of interest that you want to add. All the fields will be automatically filled.",
    sections: [
      {
        id: 1,
        image: "faq-experimentMod.svg",
        description: "Click Experiment module in the top right main navigation."
      },
      {
        id: 2,
        image: "faq-experimentSel.svg",
        description: "Check the box of the experiment of your interest."
      },
      {
        id: 3,
        image: "faq-0020_1.svg",
        description: "Click ‘+’ beside Initial Culture to open the entry form."
      },
      {
        id: 4,
        image: "faq-0020_2.svg",
        description:
          "Check ‘Fill with existing culture’ if you want to enter the same condition as the culture already present."
      },
      {
        id: 5,
        image: "faq-0020_3.svg",
        description: "Click and Select the ‘Existing culture’."
      },
      {
        id: 6,
        image: "faq-0020_4.svg",
        description:
          "Click and Select the strain of interest that you want to add."
      },
      {
        id: 7,
        image: "faq-0020_5.svg",
        description: "All the fields will be automatically filled."
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0021",
    module: "experiment",
    tags: ["experiment"],
    question: "Can you explain the initial experiment setup page",
    answer: "See images below.",
    sections: [
      {
        id: 1,
        image: "faq-0021_1.svg",
        description: "See image below."
      },
      {
        id: 2,
        image: "faq-0021_2.svg",
        description: "See image below."
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0022",
    module: "experiment",
    tags: ["culture", "experiment"],
    question: "Can you explain the entry fields in Add Culture",
    answer: "See images below.",
    sections: [
      {
        id: 1,
        image: "faq-0022_1.svg",
        description: "See image below."
      },
      {
        id: 2,
        image: "faq-0022_2.svg",
        description: "See image below."
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0023",
    module: "experiment",
    tags: ["culture", "experiment"],
    question:
      "How do I see / find the cultures that were set up previously in each stage in the experiment",
    answer: "See image below.",
    sections: [
      {
        id: 1,
        image: "faq-0023_1.svg",
        description: "See image below."
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0024",
    module: "database",
    tags: ["database"],
    question: "How do I edit the information of the database",
    answer: "See image below.",
    sections: [
      {
        id: 1,
        image: "faq-0024_1.svg",
        description:
          "Go to any database (Bioreactor is the example used here), and click the Edit button. All the editable information of the selected item will populate."
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0025",
    module: "experiment",
    tags: ["experiment"],
    question: "What is ‘Number of Stages’ in Experiment",
    answer:
      "Experiment can consist of multiple stages of cultures. For example, when strain A requires inoculum, seed culture, and production culture, ‘Number of Stages’ needs to be 3 to configure all required steps. Currently, OLAB supports up to 10 stages. The first stage will be automatically set as ‘Inoculum’ and the last stage will be set as ‘Production’.",
    sections: [
      {
        id: 1,
        image: "faq-0025_1.svg",
        description: "Located within Experiment Add Form."
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0026",
    module: "experiment",
    tags: ["experiment"],
    question: "What are ‘Control Strain’ and ‘Test Strain(s)’ in Experiment",
    answer:
      "It is sometimes difficult to keep track of right strains to compare within the experiment after the experiment is done. This will help researchers see what strains need to be compared. Control strain: This is the strain that will be compared to the test strains in the experiment. When a control strain is not available, one of the test strains can be selected. Test Strain(s): This strain is the strain of interest to test in the experiment. Multiple strains can be selected for the given experiment.",
    sections: [
      {
        id: 1,
        image: "faq-0026_1.svg",
        description: "Located within Experiment Add Form."
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0027",
    module: "experiment",
    tags: ["experiment"],
    question: "What is ‘Related Experiment’ in Experiment",
    answer:
      "It is sometimes difficult to keep track of relevant experiments to compare in the database after the experiment is done. This will help researchers see what experiments are relevant. Multiple experiments can be selected.",
    sections: [
      {
        id: 1,
        image: "faq-0027_1.svg",
        description: "Located within Experiment Add Form."
      }
    ],
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  },
  {
    olab_id: "FAQ-0028",
    module: "database",
    tags: ["database"],
    question: "What if I can't find an answer for my question in the FAQ",
    answer:
      "We sorry to hear that. Please let us know your questions below. We will update FAQ with your question answered as quickly as possible.",
    creator: "admin@olabbio.com",
    createdAt: "2024-07-20T20:41:05.169Z"
  }
];
