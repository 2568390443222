/* The list of production data supported by this class
 *
 * All models contain the following fields:
 *  olab_id, olab_type, creator, createdAt, updatedAt
 */
import axios from "axios";
import { OlabUtils } from "./olabutils.js";

class OlabProdData {
  // 20 counts per page
  static LIMIT_PER_PAGE = 20;
  static MAX_LIMIT = 2000;
  // Default category is prod_data_cfg1
  static category = "prod_data_cfg1";

  constructor(pd) {
    if (pd == null) {
      this.initProdData();
    } else {
      let hp = Object.prototype.hasOwnProperty;
      // Use internally only
      this.olab_id = hp.call(pd, "olab_id") ? pd.olab_id : null;
      this.olab_type = hp.call(pd, "olab_type") ? pd.olab_type : null;
      this.origin_csv = hp.call(pd, "origin_csv") ? pd.origin_csv : null;
      // TODO: Add this property when bio IO support it
      // this.prod_data_category = hp.call(pd, "prod_data_category")
      //   ? pd.prod_data_category
      //   : null;

      // System audit data
      this.creator = hp.call(pd, "creator") ? pd.creator : null;
      this.createdAt = hp.call(pd, "createdAt") ? pd.createdAt : null;
      this.updatedAt = hp.call(pd, "updatedAt") ? pd.updatedAt : null;

      // Sample data
      this.age = hp.call(pd, "age") ? pd.age : null;
      this.od_600nm = hp.call(pd, "od_600nm") ? pd.od_600nm : null;
      this.pcw = hp.call(pd, "pcw") ? pd.pcw : null;
      this.product_conc = hp.call(pd, "product_conc") ? pd.product_conc : null;
      this.increased_vol = hp.call(pd, "increased_vol")
        ? pd.increased_vol
        : null;
      this.fed_carbon = hp.call(pd, "fed_carbon") ? pd.fed_carbon : null;
      this.carbon_conc_measured = hp.call(pd, "carbon_conc_measured")
        ? pd.carbon_conc_measured
        : null;
      this.by_products = hp.call(pd, "by_products") ? pd.by_products : null;
      this.ph_control = hp.call(pd, "ph_control") ? pd.ph_control : null;
      this.naoh20pct_used = hp.call(pd, "naoh20pct_used")
        ? pd.naoh20pct_used
        : null;
      this.antifoam50pct_used = hp.call(pd, "antifoam50pct_used")
        ? pd.antifoam50pct_used
        : null;
      this.broth_out = hp.call(pd, "broth_out") ? pd.broth_out : null;
      this.input_output = hp.call(pd, "input_output") ? pd.input_output : null;

      // computed data
      this.total_accumulated_carbon = hp.call(pd, "total_accumulated_carbon")
        ? pd.total_accumulated_carbon
        : null;
      this.carbon_in_media = hp.call(pd, "carbon_in_media")
        ? pd.carbon_in_media
        : null;
      this.total_consumed_carbon = hp.call(pd, "total_consumed_carbon")
        ? pd.total_consumed_carbon
        : null;
      this.consumed_carbon_bw_sampling = hp.call(
        pd,
        "consumed_carbon_bw_sampling"
      )
        ? pd.consumed_carbon_bw_sampling
        : null;
      this.carbon_consumed_rate = hp.call(pd, "carbon_consumed_rate")
        ? pd.carbon_consumed_rate
        : null;
      this.product_adjusted = hp.call(pd, "product_adjusted")
        ? pd.product_adjusted
        : null;
      this.production_rate = hp.call(pd, "production_rate")
        ? pd.production_rate
        : null;
      this.yield = hp.call(pd, "yield") ? pd.yield : null;
      this.yield_12h = hp.call(pd, "yield_12h") ? pd.yield_12h : null;
    }
  }

  initProdData() {
    console.log("OlabProdData.initProdData() ...");
    // Common fields: internal use
    this.olab_id = null;
    this.olab_type = null;
    this.origin_csv = null;
    this.creator = null;
    this.createdAt = null;
    this.updatedAt = null;
    // Set category
    // this.prod_data_category = OlabProdData.category;

    this.age = null;
    this.od_600nm = null;
    this.pcw = null;
    this.product_conc = null;
    this.increased_vol = null;
    this.fed_carbon = null;
    this.carbon_conc_measured = null;
    this.by_products = null;
    this.ph_control = null;
    this.naoh20pct_used = null;
    this.antifoam50pct_used = null;
    this.broth_out = null;
    this.input_output = null;

    // computed data
    this.total_accumulated_carbon = null;
    this.carbon_in_media = null;
    this.total_consumed_carbon = null;
    this.consumed_carbon_bw_sampling = null;
    this.carbon_consumed_rate = null;
    this.product_adjusted = null;
    this.production_rate = null;
    this.yield = null;
    this.yield_12h = null;
  }

  set(pd) {
    this.olab_id = pd.olab_id;
    this.olab_type = pd.olab_type;
    this.origin_csv = pd.origin_csv;
    this.creator = pd.creator;
    this.createdAt = pd.createdAt;
    this.updatedAt = pd.updatedAt;

    this.age = pd.age;
    this.od_600nm = pd.od_600nm;
    this.pcw = pd.pcw;
    this.product_conc = pd.product_conc;
    this.increased_vol = pd.increased_vol;
    this.fed_carbon = pd.fed_carbon;
    this.carbon_conc_measured = pd.carbon_conc_measured;
    this.by_products = pd.by_products;
    this.ph_control = pd.ph_control;
    this.naoh20pct_used = pd.naoh20pct_used;
    this.antifoam50pct_used = pd.antifoam50pct_used;
    this.broth_out = pd.broth_out;
    this.input_output = pd.input_output;

    // computed data
    this.total_accumulated_carbon = pd.total_accumulated_carbon;
    this.carbon_in_media = pd.carbon_in_media;
    this.total_consumed_carbon = pd.total_consumed_carbon;
    this.consumed_carbon_bw_sampling = pd.consumed_carbon_bw_sampling;
    this.carbon_consumed_rate = pd.carbon_consumed_rate;
    this.product_adjusted = pd.product_adjusted;
    this.production_rate = pd.production_rate;
    this.yield = pd.yield;
    this.yield_12h = pd.yield_12h;
  }

  // Use axios to get production datas
  static async getProductionDatas(searchBody, statusObj) {
    // console.log("getProductionDatas' body =", searchBody);
    const productionDatas = [];
    statusObj.searching = true;
    statusObj.message = null;
    statusObj.errorMessage = null;

    // Load productionDatas
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy =
        searchField === "merge" || searchField === "origin_csv"
          ? "olab_id"
          : searchField;
      const limit = searchBody.limit
        ? searchBody.limit
        : OlabProdData.LIMIT_PER_PAGE;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/productiondatas/mergeFind",
        data: {
          search_type: "bioreactor_pd",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: null,
          page: searchBody.page ? searchBody.page : 1,
          limit: limit
        }
      });

      // console.log("productionDatas: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.productionDatas
      ) {
        // console.log("productionDatas: results = ", res.data.results);
        const pds = res.data.data.productionDatas;
        // console.log("productionDatas: pds =", pds);
        pds.forEach((pd) => {
          productionDatas.push(new OlabProdData(pd));
        });
      } else {
        console.log("productionDatas is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getProductionDatas - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return productionDatas;
  }
}
export { OlabProdData };
