<template>
  <div>
    <div class="form-group card">
      <div class="card-header text-dark">
        Production Data
        <div
          v-if="localStatusObj.progress"
          class="spinner-border spinner-border-sm text-primary"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <span v-if="localStatusObj.errorMessage" class="mx-1 text-danger">
          {{ localStatusObj.errorMessage }}
        </span>
        <span v-if="localStatusObj.message" class="mx-1 text-info">
          {{ localStatusObj.message }}
        </span>
      </div>
      <div class="card-body">
        <div v-if="batchUploads" class="mb-2">
          <div class="table-responsive">
            <table class="table table-striped table-sm">
              <thead>
                <tr>
                  <th class="small" scope="col">#</th>
                  <th class="small" scope="col">Uploaded By</th>
                  <th class="small" scope="col">Date</th>
                  <th class="small" scope="col">Category</th>
                  <th class="small" scope="col">Count</th>
                  <th class="small" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(bUpld, index) in batchUploads" :key="index">
                  <th class="small" scope="row">{{ index + 1 }}</th>
                  <td class="small">{{ bUpld.creator }}</td>
                  <td class="small">
                    {{ formatTimeStamp(bUpld.createdAt) }}
                  </td>
                  <td class="small">{{ bUpld.category }}</td>
                  <td class="small">{{ bUpld.total_count }}</td>
                  <td class="small">
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      :disabled="index === selectedIndex"
                      v-on:click="viewData(index, bUpld)"
                    >
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- {{ batchUploads }} -->
          <div class="form-row my-3 justify-content-center">
            <span>
              <button
                type="button"
                v-bind:disabled="!combinedDataPossible"
                class="btn btn-primary"
                v-on:click="downloadCSVFile(culture)"
              >
                <span
                  v-if="localStatusObj.progress"
                  class="spinner-border spinner-border-sm text-light"
                ></span>
                Download combined data as CSV file
              </button>
            </span>
          </div>
        </div>
        <div>
          <div v-if="prodDatas" class="py-1">
            <div class="card">
              <div class="card-header text-dark">Loaded Product Data</div>
              <div class="card-body">
                <div class="table-responsive">
                  <table
                    v-if="category === 'vitk2_fillins'"
                    class="table table-striped table-sm"
                  >
                    <thead>
                      <tr>
                        <th class="small" scope="col">#</th>
                        <th class="small" scope="col">Time (h)</th>
                        <th class="small" scope="col">Alkali pump</th>
                        <th class="small" scope="col">Feed pump</th>
                        <th class="small" scope="col">Air (L/min)</th>
                        <th class="small" scope="col">Feed.Sol.Density</th>
                        <th class="small" scope="col">Feed.Sol.Conc. (g/L)</th>
                        <th class="small" scope="col">Fed.Feed.Sol.Vol. (L)</th>
                        <th class="small" scope="col">Working Vol. (L)</th>
                        <th class="small" scope="col">Sample.Vol. (mL)</th>
                        <th class="small" scope="col">In.Gly.Conc. (g/L)</th>
                        <th class="small" scope="col">
                          Sp.Int.Yield (mg VK2/g DCW/g Glycerol)
                        </th>
                        <th class="small" scope="col">
                          Sp.Cum.Yield (mg Vk2/g DCW/g Glycerol)
                        </th>
                        <th class="small" scope="col">
                          Sp.Int.Gly.Up.Rate (g Glycerol/g DCW/h)
                        </th>
                        <th class="small" scope="col">
                          Sp.Cum.Gly.Up.Rate (g Glycerol/g DCW/h)
                        </th>
                        <th class="small" scope="col">
                          Sp.Int.VK2.Prod.Rate (mg VK2/g DCW/h)
                        </th>
                        <th class="small" scope="col">
                          Sp.Cum.VK2.Prod.Rate (mg VK2/g DCW/h)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(pData, index) in prodDatas" :key="index">
                        <th class="small" scope="row">{{ index + 1 }}</th>
                        <td class="small">{{ pData.time }}</td>
                        <td class="small">{{ pData.alkali_pump }}</td>
                        <td class="small">{{ pData.feed_pump }}</td>
                        <td class="small">{{ pData.air }}</td>
                        <td class="small">{{ pData.feeding_sol_dens }}</td>
                        <td class="small">{{ pData.feeding_sol_conc }}</td>
                        <td class="small">
                          {{ pData.fed_feeding_sol_vol }}
                        </td>
                        <td class="small">{{ pData.working_vol }}</td>
                        <td class="small">{{ pData.sampling_vol }}</td>
                        <td class="small">{{ pData.input_glycerol_conc }}</td>
                        <td class="small">
                          {{ pData.specific_int_yield }}
                        </td>
                        <td class="small">{{ pData.specific_cum_yield }}</td>
                        <td class="small">
                          {{ pData.specific_int_glycerol_uprate }}
                        </td>
                        <td class="small">
                          {{ pData.specific_cum_glycerol_uprate }}
                        </td>
                        <td class="small">
                          {{ pData.specific_int_vit_prodrate }}
                        </td>
                        <td class="small">
                          {{ pData.specific_cum_vit_prodrate }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    v-if="category === 'vitk2_machines'"
                    class="table table-striped table-sm"
                  >
                    <thead>
                      <tr>
                        <th class="small" scope="col">#</th>
                        <th class="small" scope="col">Time (h)</th>
                        <th class="small" scope="col">pH</th>
                        <th class="small" scope="col">DO</th>
                        <th class="small" scope="col">CO2 (%)</th>
                        <th class="small" scope="col">O2 (%)</th>
                        <th class="small" scope="col">Feed.Sol.Wt. (g)</th>
                        <th class="small" scope="col">RPM</th>
                        <th class="small" scope="col">Temp. (oC)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(pData, index) in prodDatas" :key="index">
                        <th class="small" scope="row">{{ index + 1 }}</th>
                        <td class="small">{{ pData.time }}</td>
                        <td class="small">{{ pData.ph_control }}</td>
                        <td class="small">{{ pData.do_control }}</td>
                        <td class="small">{{ pData.co2_control }}</td>
                        <td class="small">{{ pData.o2_control }}</td>
                        <td class="small">{{ pData.feeding_sol_weight }}</td>
                        <td class="small">{{ pData.rpm_control }}</td>
                        <td class="small">{{ pData.temp }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    v-if="category === 'vitk2_measurements'"
                    class="table table-striped table-sm"
                  >
                    <thead>
                      <tr>
                        <th class="small" scope="col">#</th>
                        <th class="small" scope="col">Time (h)</th>
                        <th class="small" scope="col">OD (600nm)</th>
                        <th class="small" scope="col">Glycerol conc. (g/L)</th>
                        <th class="small" scope="col">VK2 conc. (mg/L)</th>
                        <th class="small" scope="col">
                          Acetic acid conc. (g/L)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(pData, index) in prodDatas" :key="index">
                        <th class="small" scope="row">{{ index + 1 }}</th>
                        <td class="small">{{ pData.time }}</td>
                        <td class="small">{{ pData.od_600nm }}</td>
                        <td class="small">{{ pData.glycerol_conc }}</td>
                        <td class="small">{{ pData.vk2_conc }}</td>
                        <td class="small">{{ pData.acetic_acid_conc }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="mt-2 float-right">
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    v-on:click="clearDatas"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- .card-body -->
      <div class="card-footer text-center">
        <!-- <span>Footer Production data</span> -->
      </div>
    </div>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";
import { OlabDate } from "@/olab/olabdate";
import { OlabSearch } from "@/olab/olabsearch";
import { OlabProdDataVitk2 } from "@/olab/olabproddatavitk2";

export default {
  name: "ProductionData",
  data() {
    return {
      selectedIndex: -1,
      prodDatas: null,
      batchUploads: null,
      category: "",
      combinedDataPossible: false,
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      }
    };
  },
  props: ["user", "exprt", "culture", "reloadUpload"],
  async mounted() {
    // console.log("Mounted ProductionData: exprt =", this.exprt);
    // console.log("Mounted ProductionData: culture =", this.culture);
    await this.loadProductionUpload();
  },
  computed: {},
  methods: {
    formatTimeStamp(tm) {
      return OlabDate.formatTimeStamp(tm);
    },
    async viewData(index, bUpld) {
      await this.loadProdDatas(index, bUpld);
    },
    clearDatas() {
      this.prodDatas = null;
      this.selectedIndex = -1;
    },
    async loadProdDatas(index, bUpld) {
      this.selectedIndex = index;
      if (bUpld) {
        // Possible types are "bioreactor_pd", "flask_pd" and "tube_pd"
        // console.log("loadProdDatas: culture =", this.culture);
        let sType = "bioreactor_pd";
        if (this.culture) {
          sType = this.culture.container_type + "_pd";
        }
        const searchBody = OlabSearch.createSearchBody(
          sType,
          bUpld.origin_csv,
          "origin_csv"
        );
        this.category = bUpld.category;

        // Need to handle fill-in, machine, meansurement data (using category field)
        this.prodDatas = await OlabProdDataVitk2.getProductionDatas(
          bUpld.category,
          searchBody,
          this.localStatusObj
        );

        // console.log("prodDatas =", this.prodDatas);
      }
    },
    async loadProductionUpload() {
      // reset
      this.batchUploads = null;

      const uplds = await OlabAxios.loadProductionUpload(
        this.user,
        this.culture,
        this.localStatusObj
      );
      // Note: uplds.productionUpload is not use in vitk2
      if (
        uplds &&
        (uplds.fillinsUpload ||
          uplds.machinesUpload ||
          uplds.measurementsUpload)
      ) {
        // Add category property
        const fArr = uplds.fillinsUpload
          ? uplds.fillinsUpload.batch_uploads
          : [];
        fArr.forEach((ele) => {
          ele.category = "vitk2_fillins";
        });
        const maArr = uplds.machinesUpload
          ? uplds.machinesUpload.batch_uploads
          : [];
        maArr.forEach((ele) => {
          ele.category = "vitk2_machines";
        });
        const meArr = uplds.measurementsUpload
          ? uplds.measurementsUpload.batch_uploads
          : [];
        meArr.forEach((ele) => {
          ele.category = "vitk2_measurements";
        });

        // Set combined data possible flag
        this.combinedDataPossible =
          fArr.length > 0 && maArr.length > 0 && meArr.length > 0;

        // Merge fillins, machines and measurements
        this.batchUploads = [...fArr, ...maArr, ...meArr];
        // Sort the array by the 'createdAt' property in ascending order
        this.batchUploads.sort((a, b) => a.createdAt - b.createdAt);
        // console.log("batch uploads =", this.batchUploads);
      } else if (
        this.localStatusObj &&
        this.localStatusObj.errorMessage &&
        this.localStatusObj.errorMessage.includes("404")
      ) {
        // Reset errorMessage since it is possible to have no upload data
        // console.log("errorMessage =", this.localStatusObj.errorMessage);
        this.localStatusObj.errorMessage = null;
      }
      // console.log("batchUploads =", this.batchUploads);
    },
    async downloadCSVFile(cultr) {
      const csvFile = await OlabAxios.getCombinedProdDataCSVFile(
        cultr,
        this.localStatusObj
      );

      const fileName = `${cultr.name}_combined.csv`;
      if (csvFile) {
        // download file to client machine
        // console.log("Download Name: ", fileName, ", csvFile =", csvFile);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.style.visibility = "hidden";

        const blob = new Blob([csvFile], { type: "octet/stream" });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  },
  watch: {
    "culture.olab_id"() {
      // console.log("watch: culture =", this.culture);
      this.batchUploads = null;
      this.clearDatas();
      // Reload production uploads
      this.loadProductionUpload();
    },
    reloadUpload() {
      // console.log("watch: reloadUpload =", this.culture);
      // Reload production uploads
      this.loadProductionUpload();
      this.$emit("setReloadUpload", false);
    }
  }
};
</script>
