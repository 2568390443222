<template>
  <div>
    <div class="row justify-content-between">
      <div v-if="leftColors" class="col-lg-5">
        <label class="text-nowrap">{{ leftName }}:</label>
        <ul>
          <li
            v-for="item in leftColors"
            :key="item.column_name"
            @click="selectItem(item, 'left')"
            :class="{ selected: item === selectedItem }"
          >
            <!-- Color circle next to the item -->
            <div class="text-nowrap">
              <span
                class="color-circle"
                :style="{ backgroundColor: item.color }"
              ></span>
              {{ item.display_name }}
            </div>
          </li>
        </ul>
      </div>
      <div v-if="rightColors" class="col-lg-5">
        <label class="text-nowrap">{{ rightName }}:</label>
        <ul>
          <li
            v-for="item in rightColors"
            :key="item.column_name"
            @click="selectItem(item, 'right')"
            :class="{ selected: item === selectedItem }"
          >
            <!-- Color circle next to the item -->
            <div class="text-nowrap">
              <span
                class="color-circle"
                :style="{ backgroundColor: item.color }"
              ></span>
              {{ item.display_name }}
            </div>
          </li>
        </ul>
      </div>
      <div v-if="selectedItem" class="col-lg-2">
        <ColorPickerUI
          name="Color Picker"
          :color="selectedColor"
          @updateColor="updateColor"
        ></ColorPickerUI>
        <!-- <p>Selected Color : {{ selectedColor }}</p> -->
      </div>
    </div>
  </div>
</template>

<script>
import ColorPickerUI from "@/components/ui/ColorPickerUI";
export default {
  data() {
    return {
      selectedItem: null, // Track the selected item
      selectedColor: { hex: "#000000" },
      selectedColumn: ""
    };
  },
  props: ["leftName", "rightName", "leftColors", "rightColors"],
  emits: ["updateLeftColors", "updateRightColors", "emitFinalEvent"],
  components: {
    ColorPickerUI
  },
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
    window.addEventListener("mouseup", this.emitFinalEvent);
    window.addEventListener("touchend", this.emitFinalEvent);
  },
  beforeUnmount() {
    window.removeEventListener("keyup", this.onKeyup);
    window.removeEventListener("mouseup", this.emitFinalEvent);
    window.removeEventListener("touchend", this.emitFinalEvent);
  },
  computed: {},
  methods: {
    _isHexKey(evt) {
      const key = evt.key;
      return /^[A-Fa-f0-9]$/.test(key);
    },
    onKeyup(evt) {
      if (this._isHexKey(evt)) {
        // console.log("onkeyu: Hex key pressed:", evt.key);
        // Do something with the hex value
        this.$emit("emitFinalEvent", evt);
      }
    },
    emitFinalEvent(evt) {
      // console.log("emitFinalEvent =", evt);
      this.$emit("emitFinalEvent", evt);
    },
    selectItem(item, selColumn) {
      if (this.selectedItem === item) {
        // console.log("* clicked! item =", item);
        this.selectedItem = null;
      } else {
        // console.log("** clicked! item =", item);
        this.selectedItem = item; // Update the selected item
        this.selectedColor = item ? { hex: item.color } : { hex: "#000000" };
      }
      this.selectedColumn = selColumn;
    },
    updateColor(clr) {
      const selItem = this.selectedItem;
      if (selItem.color !== clr.hex && this.selectedColumn !== "") {
        // console.log("ULC: item =", selItem, ", clr =", clr);
        this.selectedColor = clr;
        const updateMethod =
          this.selectedColumn === "left"
            ? "updateLeftColors"
            : "updateRightColors";
        this.$emit(updateMethod, selItem, clr); // Emit event to update left colors in parent
      } else {
        // console.log("No update ULC: item =", selItem, ", clr =", clr);
      }
    }
  }
};
</script>

<style scoped>
/* Styling for the color circle */
.color-circle {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%; /* Make the shape a circle */
  margin-right: 10px; /* Add some space between the circle and text */
  border: 1px solid #000; /* Optional: a black border around the circle */
}

/* Styling for the selected item */
.selected {
  border: 2px solid #888; /* Add a black border to the selected item */
  color: #000;
  background-color: #f0f0f0; /* Light background for selected item */
}

li {
  cursor: pointer;
  padding: 2px;
  list-style: none;
  margin-bottom: 5px;
  border-radius: 4px;
  /* display: flex; */
  align-items: center; /* Align text and circle dot vertically */
}
li:hover {
  background-color: #f9f9f9; /* Light hover effect */
}
</style>
