<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="selectedType === 'chart'" class="container">
    <form v-on:submit.prevent="onSubmit">
      <div class="form-group">
        <label for="inp-exprt-name" class="form-control-label">
          <span>Name:</span>
        </label>
        <input
          type="text"
          class="form-control"
          v-bind:class="validState(item.name)"
          id="inp-exprt-name"
          placeholder="Name"
          v-model="item.name"
        />
      </div>
      <div class="form-row">
        <div v-if="user" class="form-group col-md-8">
          <div class="form-group">
            <label for="sel-projs">Project:</label>
            <div class="mb-2" :class="oakProjsValidate">
              <VueMultiselect
                id="sel-projs"
                v-model="selProj"
                :options="projChoices"
                :loading="isLoadingProjs"
                :internal-search="false"
                :multiple="false"
                :close-on-select="true"
                :options-limit="10"
                :custom-label="nameInfo"
                placeholder="Type to search"
                label="olab_id"
                track-by="olab_id"
                @search-change="getProjects"
                @select="validateProj"
                @remove="removeProj"
              />
            </div>
            <!-- <p v-if="selProj">
              {{ selProj.olab_id }}
            </p> -->
          </div>
        </div>
        <div
          v-if="dbInfo && dbInfo.prod_data_categories"
          class="form-group col-md-3"
        >
          <div class="form-group">
            <label for="sel-cats">Source:</label>
            <div class="mb-2" :class="oakSrcValidate">
              <VueMultiselect
                id="sel-cats"
                v-model="selSrc"
                :options="srcChoices"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                :allow-empty="true"
                deselectLabel=""
                :showLabels="false"
                label="display_name"
                track-by="key_name"
                @select="validateSrc"
                @remove="removeSrc"
              />
            </div>
            <!-- <p v-if="selSrc">{{ selSrc }}</p> -->
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="inp-desc">Description</label>
        <textarea
          type="textarea"
          class="form-control"
          v-model="item.desc"
          id="inp-desc"
        ></textarea>
      </div>
      <div class="form-group">
        <label for="inp-note">Note</label>
        <textarea
          type="textarea"
          class="form-control"
          v-model="item.note"
          id="inp-note"
        ></textarea>
      </div>
      <div class="form-group oak-flex-creator-footer">
        Created by: {{ item.creator }}
      </div>
    </form>
  </div>
  <div class="oak-flex-footer">
    <button
      type="button"
      v-bind:disabled="itemAddIsDisabled == true"
      class="btn btn-primary"
      v-on:click="handleAddItem(item)"
    >
      <span
        v-if="statusObj.progress"
        class="spinner-border spinner-border-sm text-light"
      ></span>
      Add
    </button>
    <button
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"
      v-on:click="cancelAdd()"
    >
      Cancel
    </button>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";
import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";
import { OlabSearch } from "@/olab/olabsearch";
import VueMultiselect from "vue-multiselect";

export default {
  name: "ChartAdd",
  data() {
    return {
      invalidFB: OlabItem.getInvalidFeedbacks(),
      isLoadingProjs: false,
      selProj: null,
      selSrc: null,
      projChoices: [],
      srcChoices: [],
      oakProjsValidate: "oak-invalid",
      oakSrcValidate: "oak-invalid"
    };
  },
  components: {
    VueMultiselect
  },
  props: ["user", "dbInfo", "selectedType", "item", "items", "statusObj"],
  emits: ["resetAddEnabled"],
  async mounted() {
    console.log("mounted: ChartAdd");
    // Construct source choices with dbInfo.prod_data_categories
    const dbInfoCats = this.dbInfo.prod_data_categories;
    if (dbInfoCats) {
      dbInfoCats.forEach((cat) => {
        if (cat === "prod_data_cfg1") {
          this.srcChoices.push({
            key_name: "prod_data_cfg1",
            display_name: "cfg1",
            source: "prod_data"
          });
        } else if (cat === "prod_data_cfg2") {
          this.srcChoices.push({
            key_name: "prod_data_cfg2",
            display_name: "cfg2",
            source: "prod_data"
          });
        } else if (cat === "vitk2") {
          this.srcChoices.push({
            key_name: "vitk2",
            display_name: "vitamin k2",
            source: "vitk2_data"
          });
        }
      });
    }

    await this.getProjects("");
  },
  methods: {
    async handleAddItem(item) {
      console.log("Add item = ", item, ", selectedType = ", this.selectedType);
      await OlabAxios.addItem(
        item,
        this.selectedType,
        this.items,
        this.statusObj
      );

      // Done add item
      this.$emit("resetAddEnabled");
    },
    cancelAdd() {
      this.$emit("resetAddEnabled");
    },
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    nameInfo({ olab_id, name }) {
      return `${name}: [${olab_id}]`;
    },
    async getProjects(query) {
      this.isLoadingProjs = true;
      const searchBody = OlabSearch.createSearchBody("project", query, "merge");
      this.projChoices = await OlabAxios.getProjects(
        searchBody,
        this.statusObj
      );
      this.isLoadingProjs = false;
    },
    validateProj(/*actionName*/) {
      // console.log("validateProj =", actionName);
      this.oakProjsValidate = "oak-valid";
    },
    validateSrc(/*actionName*/) {
      // console.log("validateSrc =", actionName);
      this.oakSrcValidate = "oak-valid";
    },
    removeProj(/*actionName*/) {
      // console.log("removeProj =", actionName);
      this.oakProjsValidate = "oak-invalid";
      this.selProj = null;
    },
    removeSrc(/*actionName*/) {
      // console.log("removeSrc =", actionName);
      this.oakSrcValidate = "oak-invalid";
      this.selSrc = null;
    }
  },
  computed: {
    itemAddIsDisabled() {
      return !OlabSanity.isItemValid(this.selectedType, this.item);
    }
  },
  watch: {
    selProj() {
      if (this.selProj) {
        this.item.setProject(this.selProj);
      } else {
        this.item.setProject(null);
      }
    },
    selSrc() {
      if (this.selSrc) {
        this.item.setSource(this.selSrc.source);
      } else {
        this.item.setSource(null);
      }
    }
  }
};
</script>

<style scoped>
.oak-invalid {
  background: red;
  padding: 0.06rem;
  border-radius: 0.4rem;
}

.oak-valid {
  background: rgb(1, 152, 1);
  padding: 0.06rem;
  border-radius: 0.4rem;
}

.oak-flex-creator-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.oak-flex-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 1rem;
  border-top: 0.05rem solid;
  margin: 2rm 1rem;
}
</style>
