<template>
  <div>
    <div class="form-group card">
      <div class="card-header text-dark">
        File Upload
        <div
          v-if="localStatusObj.progress"
          class="spinner-border spinner-border-sm text-primary"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <span v-if="localStatusObj.errorMessage" class="mx-1 text-danger">
          {{ localStatusObj.errorMessage }}
        </span>
        <span v-if="localStatusObj.message" class="mx-1 text-info">
          {{ localStatusObj.message }}
        </span>
        <span
          v-if="uploadMsgObj && uploadMsgObj.dataset_inserted"
          class="mx-1 text-primary"
        >
          Inserted data: {{ uploadMsgObj.dataset_inserted }}
        </span>
        <span
          v-if="uploadMsgObj && uploadMsgObj.warnings"
          class="mx-1 text-warning"
        >
          Warnings: {{ uploadMsgObj.warnings }}
        </span>
        <!-- <div>
          {{ uploadMsgObj }}
        </div> -->
      </div>
      <div class="card-body">
        <div class="mb-2">
          <label class="form-control-label mr-2">production data (csv):</label>
          <input
            id="inputCSVFile"
            type="file"
            accept=".csv"
            :disabled="uploadMsgObj"
            v-on:click="clearInputCSVFile"
            v-on:change="changeFile($event, culture, 'CSV')"
          />
          <span v-if="selectedFile">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              :disabled="previewDatas"
              v-on:click="uploadData()"
            >
              Upload
            </button>
          </span>
        </div>
        <div
          v-if="uploadMsgObj && uploadMsgObj.dataset_inserted > 0"
          class="my-1"
        >
          <label class="form-control-label mr-2">{{ previewLabel }}</label>
          <button
            type="button"
            class="btn btn-primary btn-sm"
            :disabled="previewDatas"
            v-on:click="previewdData()"
          >
            Preview
          </button>
        </div>
        <div>
          <div v-if="previewDatas" class="py-1">
            <div class="card">
              <div class="card-header text-dark">Loaded Product Data</div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th class="small" scope="col">#</th>
                        <th class="small" scope="col">Age (h)</th>
                        <th class="small" scope="col">OD(600nm)</th>
                        <th class="small" scope="col">PCW(%)</th>
                        <th class="small" scope="col">Product conc. (g/L)</th>
                        <th class="small" scope="col">Increased Volume(ml)</th>
                        <th class="small" scope="col">Fed carbon (g)</th>
                        <th class="small" scope="col">
                          Carbon conc. measured (g/L)
                        </th>
                        <th class="small" scope="col">By-products (g/L)</th>
                        <th class="small" scope="col">pH Control</th>
                        <th class="small" scope="col">20%NaOH used (L)</th>
                        <th class="small" scope="col">50% Antifoam used(ml)</th>
                        <th class="small" scope="col">B.O (L)</th>
                        <th class="small" scope="col">I/O (%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(pData, index) in previewDatas" :key="index">
                        <th class="small" scope="row">{{ index + 1 }}</th>
                        <td class="small">{{ pData.age }}</td>
                        <td class="small">{{ pData.od_600nm }}</td>
                        <td class="small">{{ pData.pcw }}</td>
                        <td class="small">{{ pData.product_conc }}</td>
                        <td class="small">{{ pData.increased_vol }}</td>
                        <td class="small">{{ pData.fed_carbon }}</td>
                        <td class="small">
                          {{ pData.carbon_conc_measured }}
                        </td>
                        <td class="small">{{ pData.by_products }}</td>
                        <td class="small">{{ pData.ph_control }}</td>
                        <td class="small">{{ pData.naoh20pct_used }}</td>
                        <td class="small">
                          {{ pData.antifoam50pct_used }}
                        </td>
                        <td class="small">{{ pData.broth_out }}</td>
                        <td class="small">{{ pData.broth_out }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="mt-2 float-right">
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    v-on:click="clearPreviewData"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- .card-body -->
      <div class="card-footer text-center">
        <span>Download Production data CSV template:</span>
        <span class="px-1">
          <a :href="templateCSV" target="_blank" class="card-link" download
            >prod_data</a
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";
import { OlabSearch } from "@/olab/olabsearch";
import { OlabProdData } from "@/olab/olabproddata";

export default {
  name: "FileUpload",
  data() {
    return {
      uploadMsgObj: null,
      previewDatas: null,
      selectedFile: null,
      // TODO: Add status alert later
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      }
    };
  },
  props: ["user", "exprt", "culture"],
  async mounted() {},
  computed: {
    previewLabel() {
      let label = "Preview up to last 20 rows:";
      if (
        this.uploadMsgObj &&
        this.uploadMsgObj.dataset_inserted > 0 &&
        this.uploadMsgObj.dataset_inserted < 20
      ) {
        label = `Preview uploaded ${this.uploadMsgObj.dataset_inserted} rows :`;
      }
      return label;
    },
    templateCSV() {
      const csvFile = "templates/" + this.culture.prod_data_category + ".csv";
      // console.log("computed CSV file =", csvFile);
      return csvFile;
    }
  },
  methods: {
    clearInputCSVFile() {
      // Clear inputCSVFile
      // console.log("Clear inputCSVFile");
      const csvFileHandle = document.getElementById("inputCSVFile");
      if (csvFileHandle) {
        csvFileHandle.value = "";
      }
      // Reset localStatusObj
      this.localStatusObj.message = null;
      this.localStatusObj.progress = false;
      this.localStatusObj.searching = false;
      this.localStatusObj.errorMessage = null;
    },
    // Upload Production Data
    async changeFile(e, cltr, fileType) {
      // console.log("changeFile e =", e.target);
      if (fileType === "CSV") {
        this.selectedFile = e.target.files[0];
        if (this.selectedFile) {
          cltr.csv_file = this.selectedFile;
        } else {
          cltr.csv_file = null;
          this.selectedFile = null;
          this.uploadMsgObj = null;
        }
      }
    },
    clearPreviewData() {
      this.previewDatas = null;
      this.uploadMsgObj = null;
      this.clearInputCSVFile();
    },
    async previewdData() {
      // Possible types are "bioreactor_pd", "flask_pd" and "tube_pd"
      // console.log("previewData: culture =", this.culture);
      let sType = "bioreactor_pd";
      if (this.culture) {
        sType = this.culture.container_type + "_pd";
      }
      const searchBody = OlabSearch.createSearchBody(
        sType,
        this.uploadMsgObj.origin_csv,
        "origin_csv"
      );
      this.previewDatas = await OlabProdData.getProductionDatas(
        searchBody,
        this.localStatusObj
      );
      // console.log("previewDatas =", this.previewDatas);
    },
    async uploadData() {
      this.uploadMsgObj = await OlabAxios.updateCultureProductionData(
        this.user,
        this.culture,
        this.culture.prod_data_category,
        this.localStatusObj
      );
      // Reset selectedFil after done uploading file
      this.selectedFile = null;
      // Request a reload of upload in ProductionData
      this.$emit("setReloadUpload", true);
      // console.log("uploadData: uploadMsgObj =", this.uploadMsgObj);
    }
  },
  watch: {
    "culture.olab_id"() {
      // console.log("watch: culture =", this.culture);
      this.clearPreviewData();
    }
  }
};
</script>
