/* The list of vitamin k2 production data supported by this class
 *
 * All models contain the following fields:
 *  olab_id, olab_type, creator, createdAt, updatedAt
 */
import axios from "axios";
import { OlabUtils } from "./olabutils.js";

class OlabProdDataVitk2 {
  // 20 counts per page
  static LIMIT_PER_PAGE = 20;
  static MAX_LIMIT = 2000;
  // Default category is vitk2_fillins
  static category = "vitk2_fillins";

  constructor(pd) {
    if (pd == null) {
      this.initProdData();
    } else {
      let hp = Object.prototype.hasOwnProperty;
      // Use internally only
      this.olab_id = hp.call(pd, "olab_id") ? pd.olab_id : null;
      this.olab_type = hp.call(pd, "olab_type") ? pd.olab_type : null;
      this.origin_csv = hp.call(pd, "origin_csv") ? pd.origin_csv : null;
      // TODO: Add this property when bio IO support it
      // this.prod_data_category = hp.call(pd, "prod_data_category")
      //   ? pd.prod_data_category
      //   : null;

      // System audit data
      this.creator = hp.call(pd, "creator") ? pd.creator : null;
      this.createdAt = hp.call(pd, "createdAt") ? pd.createdAt : null;
      this.updatedAt = hp.call(pd, "updatedAt") ? pd.updatedAt : null;

      // Sample data (ref)
      this.time = hp.call(pd, "time") ? pd.time : null;

      // Sample data (fill-ins)
      this.alkali_pump = hp.call(pd, "alkali_pump") ? pd.alkali_pump : null;
      this.feed_pump = hp.call(pd, "feed_pump") ? pd.feed_pump : null;
      this.air = hp.call(pd, "air") ? pd.air : null;
      this.feeding_sol_dens = hp.call(pd, "feeding_sol_dens")
        ? pd.feeding_sol_dens
        : null;
      this.feeding_sol_conc = hp.call(pd, "feeding_sol_conc")
        ? pd.feeding_sol_conc
        : null;
      this.fed_feeding_sol_vol = hp.call(pd, "fed_feeding_sol_vol")
        ? pd.fed_feeding_sol_vol
        : null;
      this.working_vol = hp.call(pd, "working_vol") ? pd.working_vol : null;
      this.sampling_vol = hp.call(pd, "sampling_vol") ? pd.sampling_vol : null;
      this.input_glycerol_conc = hp.call(pd, "input_glycerol_conc")
        ? pd.input_glycerol_conc
        : null;
      this.specific_int_yield = hp.call(pd, "specific_int_yield")
        ? pd.specific_int_yield
        : null;
      this.specific_cum_yield = hp.call(pd, "specific_cum_yield")
        ? pd.specific_cum_yield
        : null;
      this.specific_int_glycerol_uprate = hp.call(
        pd,
        "specific_int_glycerol_uprate"
      )
        ? pd.specific_int_glycerol_uprate
        : null;
      this.specific_cum_glycerol_uprate = hp.call(
        pd,
        "specific_cum_glycerol_uprate"
      )
        ? pd.specific_cum_glycerol_uprate
        : null;
      this.specific_int_vit_prodrate = hp.call(pd, "specific_int_vit_prodrate")
        ? pd.specific_int_vit_prodrate
        : null;
      this.specific_cum_vit_prodrate = hp.call(pd, "specific_cum_vit_prodrate")
        ? pd.specific_cum_vit_prodrate
        : null;

      // Sample data (machines)
      this.ph_control = hp.call(pd, "ph_control") ? pd.ph_control : null;
      this.do_control = hp.call(pd, "do_control") ? pd.do_control : null;
      this.co2_control = hp.call(pd, "co2_control") ? pd.co2_control : null;
      this.o2_control = hp.call(pd, "o2_control") ? pd.o2_control : null;
      this.feeding_sol_weight = hp.call(pd, "feeding_sol_weight")
        ? pd.feeding_sol_weight
        : null;
      this.rpm_control = hp.call(pd, "rpm_control") ? pd.rpm_control : null;
      this.temp = hp.call(pd, "temp") ? pd.temp : null;

      // Sample data (measurements)
      this.od_600nm = hp.call(pd, "od_600nm") ? pd.od_600nm : null;
      this.glycerol_conc = hp.call(pd, "glycerol_conc")
        ? pd.glycerol_conc
        : null;
      this.vk2_conc = hp.call(pd, "vk2_conc") ? pd.vk2_conc : null;
      this.acetic_acid_conc = hp.call(pd, "acetic_acid_conc")
        ? pd.acetic_acid_conc
        : null;

      // computed data
      // TODO:
    }
  }

  initProdData() {
    console.log("OlabProdDataVitk2.initProdData() ...");
    // Common fields: internal use
    this.olab_id = null;
    this.olab_type = null;
    this.origin_csv = null;
    this.creator = null;
    this.createdAt = null;
    this.updatedAt = null;
    // Set category
    // this.prod_data_category = OlabProdDataVitk2.category;

    // Sample data (ref)
    this.time = null;

    // Sample data (fill-ins)
    this.alkali_pump = null;
    this.feed_pump = null;
    this.air = null;
    this.feeding_sol_dens = null;
    this.feeding_sol_conc = null;
    this.fed_feeding_sol_vol = null;
    this.working_vol = null;
    this.sampling_vol = null;
    this.input_glycerol_conc = null;
    this.specific_int_yield = null;
    this.specific_cum_yield = null;
    this.specific_int_glycerol_uprate = null;
    this.specific_cum_glycerol_uprate = null;
    this.specific_int_vit_prodrate = null;
    this.specific_cum_vit_prodrate = null;

    // Sample data (machines)
    this.ph_control = null;
    this.do_control = null;
    this.co2_control = null;
    this.o2_control = null;
    this.feeding_sol_weight = null;
    this.rpm_control = null;
    this.temp = null;

    // Sample data (measurements)
    this.od_600nm = null;
    this.glycerol_conc = null;
    this.vk2_conc = null;
    this.acetic_acid_conc = null;

    // computed data
    // TODO:
  }

  set(pd) {
    this.olab_id = pd.olab_id;
    this.olab_type = pd.olab_type;
    this.origin_csv = pd.origin_csv;
    this.creator = pd.creator;
    this.createdAt = pd.createdAt;
    this.updatedAt = pd.updatedAt;

    // Sample data (ref)
    this.time = pd.time;

    // Sample data (fill-ins)
    this.alkali_pump = pd.alkali_pump;
    this.feed_pump = pd.feed_pump;
    this.air = pd.air;
    this.feeding_sol_dens = pd.feeding_sol_dens;
    this.feeding_sol_conc = pd.feeding_sol_conc;
    this.fed_feeding_sol_vol = pd.fed_feeding_sol_vol;
    this.working_vol = pd.working_vol;
    this.sampling_vol = pd.sampling_vol;
    this.input_glycerol_conc = pd.input_glycerol_conc;
    this.specific_int_yield = pd.specific_int_yield;
    this.specific_cum_yield = pd.specific_cum_yield;
    this.specific_int_glycerol_uprate = pd.specific_int_glycerol_uprate;
    this.specific_cum_glycerol_uprate = pd.specific_cum_glycerol_uprate;
    this.specific_int_vit_prodrate = pd.specific_int_vit_prodrate;
    this.specific_cum_vit_prodrate = pd.specific_cum_vit_prodrate;

    // Sample data (machines)
    this.ph_control = pd.ph_control;
    this.do_control = pd.do_control;
    this.co2_control = pd.co2_control;
    this.o2_control = pd.o2_control;
    this.feeding_sol_weight = pd.feeding_sol_weight;
    this.rpm_control = pd.rpm_control;
    this.temp = pd.temp;

    // Sample data (measurements)
    this.od_600nm = pd.od_600nm;
    this.glycerol_conc = pd.glycerol_conc;
    this.vk2_conc = pd.vk2_conc;
    this.acetic_acid_conc = pd.acetic_acid_conc;

    // computed data
    // TODO:
  }

  // Use axios to get production datas
  static async getProductionDatas(category, searchBody, statusObj) {
    // console.log("** getProductionDatas' body =", searchBody);
    const productionDatas = [];
    statusObj.searching = true;
    statusObj.message = null;
    statusObj.errorMessage = null;

    // Load productionDatas
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy =
        searchField === "merge" || searchField === "origin_csv"
          ? "olab_id"
          : searchField;
      const limit = searchBody.limit
        ? searchBody.limit
        : OlabProdDataVitk2.LIMIT_PER_PAGE;

      // Set OlabProdDataVitk2.category and URL based on category
      OlabProdDataVitk2.category = category;
      // Default is vitk2_fillins
      let url = "/api/v1/fillins/mergeFind";
      if (category === "vitk2_machines") {
        url = "/api/v1/machines/mergeFind";
      } else if (category === "vitk2_measurements") {
        url = "/api/v1/measurements/mergeFind";
      }

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: url,
        data: {
          search_type: searchBody.searchType,
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: null,
          page: searchBody.page ? searchBody.page : 1,
          limit: limit
        }
      });

      // console.log("productionDatas: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.productionDatas
      ) {
        // console.log("productionDatas: results = ", res.data.results);
        const pds = res.data.data.productionDatas;
        // console.log("productionDatas: pds =", pds);
        pds.forEach((pd) => {
          productionDatas.push(new OlabProdDataVitk2(pd));
        });
      } else {
        console.log("productionDatas is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getProductionDatas - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    // console.log("productionsDatas =", productionDatas);
    return productionDatas;
  }
}
export { OlabProdDataVitk2 };
