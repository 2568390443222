<template>
  <div>
    <div v-if="selectedExprt && item">
      <div class="card">
        <div class="card-body mb-3">
          <div v-if="branch" class="my-3 h5 text-center">
            Branch: {{ branch.olab_id }} - {{ branch.name }}
          </div>
          <div class="form-row">
            <div class="form-group col-md-3">
              <label for="inp-start-date" class="form-control-label">
                <span class="font-weight-bold">Start Date:</span>
              </label>
              <input
                type="date"
                class="form-control"
                v-bind:class="validState(item.start_date)"
                id="inp-start-date"
                placeholder="Start Date"
                :value="item.start_date"
                @input.prevent="updateStartDate"
              />
            </div>
            <div class="form-group col-md-3">
              <label for="inp-end-date" class="form-control-label">
                <span class="font-weight-bold">End Date:</span>
              </label>
              <input
                type="date"
                class="form-control"
                id="inp-end-date"
                placeholder="End Date"
                :value="item.end_date"
                @input.prevent="updateEndDate"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="sel-verifier">Verifier:</label>
              <span
                v-if="verifier == null"
                class="text-danger font-weight-bold mx-1"
                >*</span
              >
              <div>
                <VueMultiselect
                  id="sel-verifier"
                  v-model="verifier"
                  :options="users"
                  :loading="isLoadingVerfr"
                  :internal-search="false"
                  :multiple="false"
                  :close-on-select="true"
                  placeholder="Select a Verifier ..."
                  :options-limit="10"
                  :custom-label="verifierInfo"
                  label="olab_id"
                  track-by="olab_id"
                  @search-change="getVerifiers"
                />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inp-verifier-sign" class="form-control-label">
                <span>Verifier Signature:</span>
              </label>
              <input
                id="inp-verifier-sign"
                class="form-control"
                type="text"
                placeholder="Verifier Signature"
                :disabled="!verifier || verifier.email !== user.email"
                :value="item.verifier_signature"
                @input.prevent="updateVerifierSignature"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="inp-date-verified" class="form-control-label">
                <span>Date:</span>
              </label>
              <input
                id="inp-date-verified"
                class="form-control"
                type="date"
                placeholder="Verified Date"
                :disabled="!verifier || verifier.email !== user.email"
                :value="item.verified_date"
                @input.prevent="updateVerifiedDate"
              />
            </div>
          </div>

          <div class="mt-2 form-row justify-content-between font-italic">
            <div class="col-md-6">Updated at: {{ formatTimeStamp }}</div>
            <div class="col-md-6">Created by: {{ item.creator }}</div>
          </div>
        </div>
      </div>

      <div v-if="finalCultureStage > 0" ref="CultureAdd00" class="mt-3">
        <CultureControl
          :user="user"
          :selectedExprt="selectedExprt"
          cultureStage="00"
          :statusObj="statusObj"
          @scrollIntoCultureAdd="scrollIntoCultureAdd"
        />
      </div>
      <div v-if="finalCultureStage > 1" ref="CultureAdd01" class="mt-3">
        <CultureControl
          :user="user"
          :selectedExprt="selectedExprt"
          cultureStage="01"
          :statusObj="statusObj"
          @scrollIntoCultureAdd="scrollIntoCultureAdd"
        />
      </div>
      <div v-if="finalCultureStage > 2" ref="CultureAdd02" class="mt-3">
        <CultureControl
          :user="user"
          :selectedExprt="selectedExprt"
          cultureStage="02"
          :statusObj="statusObj"
          @scrollIntoCultureAdd="scrollIntoCultureAdd"
        />
      </div>
      <div v-if="finalCultureStage > 3" ref="CultureAdd03" class="mt-3">
        <CultureControl
          :user="user"
          :selectedExprt="selectedExprt"
          cultureStage="03"
          :statusObj="statusObj"
          @scrollIntoCultureAdd="scrollIntoCultureAdd"
        />
      </div>
      <div v-if="finalCultureStage > 4" ref="CultureAdd04" class="mt-3">
        <CultureControl
          :user="user"
          :selectedExprt="selectedExprt"
          cultureStage="04"
          :statusObj="statusObj"
          @scrollIntoCultureAdd="scrollIntoCultureAdd"
        />
      </div>
      <div v-if="finalCultureStage > 5" ref="CultureAdd05" class="mt-3">
        <CultureControl
          :user="user"
          :selectedExprt="selectedExprt"
          cultureStage="05"
          :statusObj="statusObj"
          @scrollIntoCultureAdd="scrollIntoCultureAdd"
        />
      </div>
      <div v-if="finalCultureStage > 6" ref="CultureAdd06" class="mt-3">
        <CultureControl
          :user="user"
          :selectedExprt="selectedExprt"
          cultureStage="06"
          :statusObj="statusObj"
          @scrollIntoCultureAdd="scrollIntoCultureAdd"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CultureControl from "@/components/exprts/CultureControl.vue";
import { OlabAxios } from "@/olab/olabaxios";
// import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";
import { OlabSearch } from "@/olab/olabsearch";
import VueMultiselect from "vue-multiselect";
import { OlabDate } from "@/olab/olabdate";

export default {
  name: "ExprtDesign",
  data() {
    return {
      item: this.selectedExprt,
      branch: null,
      // controlStrain: null,
      // testStrains: [],
      // relatedExprts: [],
      users: [],
      isLoadingVerfr: false,
      verifier: null
    };
  },
  props: [
    "user",
    "dbInfo",
    "selectedType",
    "selectedExprt",
    "exprts",
    "statusObj"
  ],
  components: {
    VueMultiselect,
    CultureControl
  },
  async mounted() {
    // console.log("ExprtDesign mounted()");
    // Loading is needed for the first item
    await this.getVerifiers("");
    await this.loadExperimentData();
  },
  computed: {
    finalCultureStage() {
      return this.selectedExprt ? this.selectedExprt.num_culture_stages : 0;
    },
    formatTimeStamp() {
      let timeStampStr = "";
      if (this.item) {
        const createdAt = this.item.createdAt;
        const updatedAt = this.item.updatedAt;
        timeStampStr = OlabDate.formatTimeStamp(
          updatedAt ? updatedAt : createdAt,
          false
        );
      }
      return timeStampStr;
    }
  },
  methods: {
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    verifierInfo({ name, email }) {
      return `${name} - ${email}`;
    },
    async loadExperimentData() {
      if (this.item && this.item.olab_type === "experiment") {
        const itemData = await OlabAxios.getExperimentData(
          this.item,
          this.statusObj
        );
        // console.log("itemData =", itemData);
        this.branch = itemData.branch;
        // this.controlStrain = itemData.control_strain;
        // this.testStrains = itemData.test_strains;
        // this.relatedExprts = itemData.related_exprts;
        this.verifier = itemData.verifier;
      }
    },
    async getVerifiers(query) {
      // console.log("getVerifiers: query = ", query);
      this.isLoadingVerfr = true;
      const searchBody = OlabSearch.createSearchBody("user", query, "merge");
      this.users = await OlabAxios.getUsers(searchBody, this.statusObj);
      this.isLoadingVerfr = false;
    },
    async updateExperiment() {
      // console.log("updateExperiment: item =", this.item);
      // console.log("verifier_id =", this.item.verifier_id);
      // console.log("start_date =", this.item.start_date);
      // console.log("end_date =", this.item.end_date);
      // console.log("verifier_signature =", this.item.verifier_signature);
      // console.log("verified_date =", this.item.verified_date);
      await OlabAxios.updateExperiment(this.user, this.item, this.statusObj);
    },
    async verifyExperiment() {
      // console.log("verifyExperiment: item =", this.item);
      // console.log("verifier_id =", this.item.verifier_id);
      // console.log("verifier_signature =", this.item.verifier_signature);
      // console.log("verified_date =", this.item.verified_date);
      await OlabAxios.verifyExperiment(this.user, this.item, this.statusObj);
    },
    updateStartDate(e) {
      if (this.item) {
        const sDate = e.target.value;
        this.item.start_date = sDate;
        this.updateExperiment();
      }
    },
    updateEndDate(e) {
      if (this.item) {
        const eDate = e.target.value;
        this.item.end_date = eDate;
        this.updateExperiment();
      }
    },
    updateVerifierSignature(e) {
      if (this.item) {
        const verSignature = e.target.value;
        this.item.verifier_signature = verSignature;
        this.verifyExperiment();
      }
    },
    updateVerifiedDate(e) {
      if (this.item) {
        const verDate = e.target.value;
        this.item.verified_date = verDate;
        this.verifyExperiment();
      }
    },
    // Scroll to the respective CultureAdd top
    scrollIntoCultureAdd(caStage) {
      const csRef = `CultureAdd${caStage}`;
      this.$refs[csRef].scrollIntoView(true);
      const scrolledY = window.scrollY;
      if (scrolledY) {
        // 135 is approx. the sticky top offset height
        window.scroll(0, scrolledY - 135);
      }
    }
  },
  watch: {
    verifier() {
      // console.log("verifier =", this.verifier);
      if (!this.verifier) {
        // Assign null to verifier_id and reset verifier_signature
        // and verified_date
        this.item.verifier_id = null;
        this.item.verifier_signature = null;
        this.item.verified_date = null;
        this.updateExperiment();
      } else if (this.verifier.olab_id !== this.item.verifier_id) {
        // Assign ....
        // console.log("Assign new verifier =", this.verifier);
        this.item.verifier_id = this.verifier.olab_id;
        this.item.verifier_signature = null;
        this.item.verified_date = null;
        this.updateExperiment();
      }
    }
  }
};
</script>
