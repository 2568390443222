export const chartConfigSettingModel = [
  {
    key_name: "y_axes_range",
    display_name: "Y Axes Range"
  },
  {
    key_name: "plot_color",
    display_name: "Plot Color",
    $isDisabled: false
    // $isDisabled: true
  }
];
