export const prodDataVitk2Model = [
  {
    key_name: "time",
    base_unit: "hour",
    display_name: "Time (h)"
  },
  {
    key_name: "alkali_pump",
    base_unit: "no_unit",
    display_name: "Alkali pump"
  },
  {
    key_name: "feed_pump",
    base_unit: "no_unit",
    display_name: "Feed pump"
  },
  {
    key_name: "air",
    base_unit: "liter/minute",
    display_name: "Air"
  },
  {
    key_name: "feeding_sol_dens",
    base_unit: "no_unit",
    display_name: "Feed.Sol.Density"
  },
  {
    key_name: "feeding_sol_conc",
    base_unit: "gram/liter",
    display_name: "Feed.Sol.Conc."
  },
  {
    key_name: "fed_feeding_sol_vol",
    base_unit: "liter",
    display_name: "Fed.Feed.Sol.Vol."
  },
  {
    key_name: "working_vol",
    base_unit: "liter",
    display_name: "Working Vol."
  },
  {
    key_name: "sampling_vol",
    base_unit: "milliliter",
    display_name: "Sample.Vol."
  },
  {
    key_name: "input_glycerol_conc",
    base_unit: "gram/liter",
    display_name: "In.Gly.Conc."
  },
  {
    key_name: "specific_int_yield",
    base_unit: "milligram/(gram*gram)",
    display_name: "Sp.Int.Yield"
  },
  {
    key_name: "specific_cum_yield",
    base_unit: "milligram/(gram*gram)",
    display_name: "Sp.Cum.Yield"
  },
  {
    key_name: "specific_int_glycerol_uprate",
    base_unit: "gram/(gram*hour)",
    display_name: "Sp.Int.Gly.Up.Rate"
  },
  {
    key_name: "specific_cum_glycerol_uprate",
    base_unit: "gram/(gram*hour)",
    display_name: "Sp.Cum.Gly.Up.Rate"
  },
  {
    key_name: "specific_int_vit_prodrate",
    base_unit: "milligram/(gram*hour)",
    display_name: "Sp.Int.VK2.Prod.Rate"
  },
  {
    key_name: "specific_cum_vit_prodrate",
    base_unit: "milligram/(gram*hour)",
    display_name: "Sp.Cum.VK2.Prod.Rate"
  },
  // machines
  {
    key_name: "ph_control",
    base_unit: "no_unit",
    display_name: "pH"
  },
  {
    key_name: "do_control",
    base_unit: "no_unit",
    display_name: "DO"
  },
  {
    key_name: "co2_control",
    base_unit: "percent",
    display_name: "CO2"
  },
  {
    key_name: "o2_control",
    base_unit: "percent",
    display_name: "O2"
  },
  {
    key_name: "feeding_sol_weight",
    base_unit: "gram",
    display_name: "Feed.Sol.Wt."
  },
  {
    key_name: "rpm_control",
    base_unit: "no_unit",
    display_name: "RPM"
  },
  {
    key_name: "temp",
    base_unit: "degrees_celcius",
    display_name: "Temp."
  },
  // measurements
  {
    key_name: "od_600nm",
    base_unit: "no_unit",
    display_name: "OD (600nm)"
  },
  {
    key_name: "glycerol_conc",
    base_unit: "gram/liter",
    display_name: "Glycerol conc."
  },
  {
    key_name: "vk2_conc",
    base_unit: "milligram/liter",
    display_name: "VK2 conc."
  },
  {
    key_name: "acetic_acid_conc",
    base_unit: "gram/liter",
    display_name: "Acetic acid conc."
  },
  // computed
  {
    key_name: "input_glycerol_mass",
    base_unit: "gram",
    display_name: "In.Gly.Mass (g)"
  },
  {
    key_name: "consumed_glycerol",
    base_unit: "gram",
    display_name: "Used Gly. (g)"
  },
  {
    key_name: "vitk2_production",
    base_unit: "milligram",
    display_name: "VK2 Prod. (mg)"
  },
  {
    key_name: "vol_int_yield",
    base_unit: "no_unit",
    display_name: "Vol.Int.Yield (mg/g)"
  },
  {
    key_name: "vol_cum_yield",
    base_unit: "no_unit",
    display_name: "Vol.Cum.Yield (mg/g)"
  },
  {
    key_name: "vol_int_glycerol_uprate",
    base_unit: "gram/hour",
    display_name: "Vol.Int.Gly.Up.Rate (g/h)"
  },
  {
    key_name: "vol_cum_glycerol_uprate",
    base_unit: "gram/hour",
    display_name: "Vol.Cum.Gly.Up.Rate (g/h)"
  },
  {
    key_name: "vol_int_vit_prodrate",
    base_unit: "milligram/hour",
    display_name: "Vol.Int.VK2.Prod.Rate (mg/h)"
  },
  {
    key_name: "vol_cum_vit_prodrate",
    base_unit: "milligram/hour",
    display_name: "Vol.Cum.VK2.Prod.Rate (mg/h)"
  }
];
