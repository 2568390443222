/* The list of cfg2 production data supported by this class
 *
 * All models contain the following fields:
 *  olab_id, olab_type, creator, createdAt, updatedAt
 */
import axios from "axios";
import { OlabUtils } from "./olabutils.js";

class OlabProdDataCfg2 {
  // 20 counts per page
  static LIMIT_PER_PAGE = 20;
  static MAX_LIMIT = 2000;
  // Default category is prod_data_cfg2
  static category = "prod_data_cfg2";

  constructor(pd) {
    if (pd == null) {
      this.initProdData();
    } else {
      let hp = Object.prototype.hasOwnProperty;
      // Use internally only
      this.olab_id = hp.call(pd, "olab_id") ? pd.olab_id : null;
      this.olab_type = hp.call(pd, "olab_type") ? pd.olab_type : null;
      this.origin_csv = hp.call(pd, "origin_csv") ? pd.origin_csv : null;

      // System audit data
      this.creator = hp.call(pd, "creator") ? pd.creator : null;
      this.createdAt = hp.call(pd, "createdAt") ? pd.createdAt : null;
      this.updatedAt = hp.call(pd, "updatedAt") ? pd.updatedAt : null;
      // TODO: Add this property when bio IO support it
      // this.category = hp.call(pd, "category") ? pd.category : null;

      // Sample data
      this.time = hp.call(pd, "time") ? pd.time : null;
      this.od_600nm = hp.call(pd, "od_600nm") ? pd.od_600nm : null;
      this.ph_control = hp.call(pd, "ph_control") ? pd.ph_control : null;
      this.glucose = hp.call(pd, "glucose") ? pd.glucose : null;
      this.arabionose = hp.call(pd, "arabionose") ? pd.arabionose : null;
      this.propionate = hp.call(pd, "propionate") ? pd.propionate : null;
      this.lactate = hp.call(pd, "lactate") ? pd.lactate : null;
      this.acetate = hp.call(pd, "acetate") ? pd.acetate : null;
      this.ethanol = hp.call(pd, "ethanol") ? pd.ethanol : null;
      this.glycerol = hp.call(pd, "glycerol") ? pd.glycerol : null;
      this.squalene = hp.call(pd, "squalene") ? pd.squalene : null;
      this.squalane = hp.call(pd, "squalane") ? pd.squalane : null;

      // Computed data
      this.consumed_glucose = hp.call(pd, "consumed_glucose")
        ? pd.consumed_glucose
        : null;
      this.consumed_arabionose = hp.call(pd, "consumed_arabionose")
        ? pd.consumed_arabionose
        : null;
      this.propionate_glucose = hp.call(pd, "propionate_glucose")
        ? pd.propionate_glucose
        : null;
      this.lactate_glucose = hp.call(pd, "lactate_glucose")
        ? pd.lactate_glucose
        : null;
      this.acetate_glucose = hp.call(pd, "acetate_glucose")
        ? pd.acetate_glucose
        : null;
      this.ethanol_glucose = hp.call(pd, "ethanol_glucose")
        ? pd.ethanol_glucose
        : null;
      this.squalene_glucose = hp.call(pd, "squalene_glucose")
        ? pd.squalene_glucose
        : null;
      this.squalane_glucose = hp.call(pd, "squalane_glucose")
        ? pd.squalane_glucose
        : null;
    }
  }

  initProdData() {
    console.log("OlabProdDataCfg2.initProdData() ...");
    // Common fields: internal use
    this.olab_id = null;
    this.olab_type = null;
    this.origin_csv = null;
    this.creator = null;
    this.createdAt = null;
    this.updatedAt = null;
    // Set category
    // this.category = OlabProdDataCfg2.category;

    this.time = null;
    this.od_600nm = null;
    this.ph_control = null;
    this.glucose = null;
    this.arabionose = null;
    this.propionate = null;
    this.lactate = null;
    this.acetate = null;
    this.ethanol = null;
    this.glycerol = null;
    this.squalene = null;
    this.squalane = null;

    //  computed data
    this.consumed_glucose = null;
    this.consumed_arabionose = null;
    this.propionate_glucose = null;
    this.lactate_glucose = null;
    this.acetate_glucose = null;
    this.ethanol_glucose = null;
    this.squalene_glucose = null;
    this.squalane_glucose = null;
  }

  set(pd) {
    this.olab_id = pd.olab_id;
    this.olab_type = pd.olab_type;
    this.origin_csv = pd.origin_csv;
    this.creator = pd.creator;
    this.createdAt = pd.createdAt;
    this.updatedAt = pd.updatedAt;

    this.time = pd.time;
    this.od_600nm = pd.od_600nm;
    this.ph_control = pd.ph_control;
    this.glucose = pd.glucose;
    this.arabionose = pd.arabionose;
    this.propionate = pd.propionate;
    this.lactate = pd.lactate;
    this.acetate = pd.acetate;
    this.ethanol = pd.ethanol;
    this.glycerol = pd.glycerol;
    this.squalene = pd.squalene;
    this.squalane = pd.squalane;

    //  computed data
    this.consumed_glucose = pd.consumed_glucose;
    this.consumed_arabionose = pd.consumed_arabionose;
    this.propionate_glucose = pd.propionate_glucose;
    this.lactate_glucose = pd.lactate_glucose;
    this.acetate_glucose = pd.acetate_glucose;
    this.ethanol_glucose = pd.ethanol_glucose;
    this.squalene_glucose = pd.squalene_glucose;
    this.squalane_glucose = pd.squalane_glucose;
  }

  // Use axios to get production datas
  static async getProductionDatas(searchBody, statusObj) {
    // console.log("getProductionDatas' body =", searchBody);
    const productionDatas = [];
    statusObj.searching = true;
    statusObj.message = null;
    statusObj.errorMessage = null;

    // Load productionDatas
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy =
        searchField === "merge" || searchField === "origin_csv"
          ? "olab_id"
          : searchField;
      const limit = searchBody.limit
        ? searchBody.limit
        : OlabProdDataCfg2.LIMIT_PER_PAGE;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/productiondatas/mergeFind",
        data: {
          search_type: "bioreactor_pd",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: null,
          page: searchBody.page ? searchBody.page : 1,
          limit: limit
        }
      });

      // console.log("productionDatas: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.productionDatas
      ) {
        // console.log("productionDatas: results = ", res.data.results);
        const pds = res.data.data.productionDatas;
        // console.log("productionDatas: pds =", pds);
        pds.forEach((pd) => {
          productionDatas.push(new OlabProdDataCfg2(pd));
        });
      } else {
        console.log("productionDatas is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getProductionDatas - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return productionDatas;
  }
}
export { OlabProdDataCfg2 };
